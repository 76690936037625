import { create } from 'zustand';
import { Props as BaseAlertProps } from '@components/v2/Alert';
import { ConfirmProps as BaseConfirmProps} from '@components/v2/Confirm';

type AlertProps = Partial<Omit<BaseAlertProps, 'isOpen'>> | null;

interface AlertPropsState {
  props: AlertProps;
  setAlert: (props: AlertProps) => void;
}

export const useAlert = create<AlertPropsState>((set) => ({
  props: null,
  setAlert: (props: AlertProps) => set(() => ({ props })),
}));

type ConfirmProps = Partial<Omit<BaseConfirmProps, 'isOpen'>> | null;

interface ConfirmPropsState {
  props: ConfirmProps;
  setConfirm: (props: ConfirmProps) => void;
}

export const useConfirm = create<ConfirmPropsState>((set) => ({
  props: null,
  setConfirm: (props: ConfirmProps) => set(() => ({ props })),
}));
