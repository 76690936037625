import React, { useMemo } from 'react';

import { Global } from "@emotion/react";
import cssVariables from './variables';

export default function GlobalStyles() {
  const cssRootVariables = useMemo(() => cssVariables, []);

  return (
    <>
      <Global
        styles={{
          ':root': cssRootVariables,
        }}
      />
    </>
  );
}
