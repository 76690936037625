import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface SalaryStateType {
  salaryStatus: 'create' | 'modify';
  deductionStatus: 'create' | 'modify';
  allowanceItemId: number;
  deductionItemId: number;
}
const initialState: SalaryStateType = {
  salaryStatus: 'create',
  deductionStatus: 'create',
  allowanceItemId: 0,
  deductionItemId: 0,
};

export const salarySlice = createSlice({
  name: 'SALARY',
  initialState,
  reducers: {
    setSalaryStatus: (state, action: PayloadAction<'create' | 'modify'>) => {
      state.salaryStatus = action.payload;
    },
    setDeductionStatus: (state, action: PayloadAction<'create' | 'modify'>) => {
      state.deductionStatus = action.payload;
    },
    setAllowanceItemId: (state, action: PayloadAction<number>) => {
      state.allowanceItemId = action.payload;
    },
    setDeductionItemId: (state, action: PayloadAction<number>) => {
      state.deductionItemId = action.payload;
    },
  },
});

export const { actions: salarySliceActions, reducer: salarySliceReducer } =
  salarySlice;

export default salarySlice;
