import { Flex, Box, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';

export const ErrorContainer = styled(Flex)`
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  margin-top: -5px;
`;

export const CautionWrapper = styled(Box)`
  margin: 54px 0;
`;

export const TextContainer = styled(Box)`
  text-align: center;
`;

export const TitleWrapper = styled(Text)`
  margin-bottom: 16px;
  font-size: 19px;
  font-weight: 700;
  letter-spacing: -0.19px;
`;

export const DescriptionWrapper = styled(Text)`
  font-size: 16px;
  letter-spacing: -0.32px;
`;

export const ButtonContainer = styled(Flex)`
  column-gap: 20px;
  margin-top: 60px;
`;
