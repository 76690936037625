const variables = {
  // NEUTRAL
  '--N10': '#f9fafb',
  '--N20': '#f1f3f5',
  '--N30': '#e9ecef',
  '--N40': '#dee1e6',
  '--N50': '#c2c8cf',
  '--N60': '#b1b8c0',
  '--N90': '#8c95a1',
  '--N200': '#6c7682',
  '--N500': '#4c5561',
  '--N700': '#333c47',
  '--N900': '#1b2027',

  // BLUE
  '--B10': '#f0f7ff',
  '--B50': '#e6f3fe',
  '--B100': '#cde5fe',
  '--B200': '#aad4fd',
  '--B300': '#79bafc',
  '--B400': '#57a9fb',
  '--B500': '#429efa',
  '--B600': '#208df9',
  '--B700': '#0679ef',
  '--B800': '#0569cc',
  '--B900': '#055db3',

  // RED
  '--R10': '#fff3f2',
  '--R50': '#fee9e6',
  '--R100': '#fed3cd',
  '--R200': '#fdb4aa',
  '--R300': '#fc8979',
  '--R400': '#fb6a57',
  '--R500': '#fa4f38',
  '--R600': '#f93015',
  '--R700': '#e01f06',
  '--R800': '#c71c05',
  '--R900': '#b31905',

  // YELLOW
  '--Y10': '#fffbe8',
  '--Y50': '#fff8d6',
  '--Y100': '#fff2b2',
  '--Y200': '#ffeb84',
  '--Y300': '#ffe047',
  '--Y400': '#fad500',
  '--Y500': '#ffc400',
  '--Y600': '#ffab00',
  '--Y700': '#ff991f',
  '--Y800': '#f08c00',
  '--Y900': '#e67b00',

  // GREEN
  '--G10': '#f2faf7',
  '--G50': '#e4f6ef',
  '--G100': '#c9eddf',
  '--G200': '#a9e5cc',
  '--G300': '#82d9b5',
  '--G400': '#4eca97',
  '--G500': '#37b983',
  '--G600': '#31a575',
  '--G700': '#2a8d64',
  '--G800': '#267e59',
  '--G900': '#227251',

  // PINK
  '--P10': '#fdf4f7',
  '--P50': '#fbe9ef',
  '--P100': '#f8d8e3',
  '--P200': '#f4c3d4',
  '--P300': '#eea0bc',
  '--P400': '#ec89ad',
  '--P500': '#e8739e',
  '--P600': '#e4588a',
  '--P700': '#df3874',

  // EXTRA
  '--white': '#ffffff',
  '--black': '#000000',
  '--hover': 'rgba(177, 186, 202, 0.1)',

  // Transparency Dark
  '--TD000': 'rgba(0, 0, 0, 0)',
  '--TD004': 'rgba(0, 0, 0, 0.04)',
  '--TD006': 'rgba(0, 0, 0, 0.06)',
  '--TD015': 'rgba(0, 0, 0, 0.15)',
  '--TD030': 'rgba(0, 0, 0, 0.3)',
  '--TD054': 'rgba(0, 0, 0, 0.54)',
  '--TD068': 'rgba(0, 0, 0, 0.68)',
  '--TD084': 'rgba(0, 0, 0, 0.84)',

  // Transparency Light
  '--TL000': 'rgba(255, 255, 255, 0)',
  '--TL004': 'rgba(255, 255, 255, 0.04)',
  '--TL006': 'rgba(255, 255, 255, 0.06)',
  '--TL015': 'rgba(255, 255, 255, 0.15)',
  '--TL030': 'rgba(255, 255, 255, 0.3)',
  '--TL054': 'rgba(255, 255, 255, 0.54)',
  '--TL068': 'rgba(255, 255, 255, 0.68)',
  '--TL084': 'rgba(255, 255, 255, 0.84)',
};

export default Object.freeze(variables);
