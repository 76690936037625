import { ChakraMultiPartComponentType } from '../type';

const parts = ['container', 'control', 'label'] as const;

{/** FIXME: first-child 사용 브라우저 warning 수정 */}
export const Radio: ChakraMultiPartComponentType<typeof parts> = {
  parts,
  baseStyle: {
    container: {
      '&:not(:first-child)': {
        marginLeft: '12px',
      },
    },
    label: {
      textStyle: 'caption1_regular',
      color: 'netural.500',
    },
  },
  defaultProps: {
    colorScheme: 'green',
    display: 'flex',
  },
  sizes: {},
  variants: {},
};
