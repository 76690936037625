import Alert from '@components/v2/Alert';
import Confirm from '@components/v2/Confirm';

import { useAlert, useConfirm } from './store';

export default function ModalProvider() {
  const { props: alertProps, setAlert } = useAlert((state) => state);
  const { props: confirmProps, setConfirm } = useConfirm((state) => state);

  return (
    <>
      <Alert
        {...alertProps}
        isOpen={!!alertProps}
        onClose={typeof alertProps?.onClose === 'function' ? alertProps.onClose : () => setAlert(null)}
      >
        {alertProps?.children}
      </Alert>

      <Confirm
        {...confirmProps}
        isOpen={!!confirmProps}
        onClose={typeof confirmProps?.onClose === 'function' ? confirmProps.onClose : () => setConfirm(null)}
        onCancel={typeof confirmProps?.onCancel === 'function' ? confirmProps.onCancel : () => setConfirm(null)}
      >
        {confirmProps?.children}
      </Confirm>
    </>
  );
}
