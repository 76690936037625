import { ComponentSingleStyleConfig } from '@chakra-ui/react';
import { StyleObjectOrFn } from '@chakra-ui/styled-system';
import { ButtonKDS } from "@theme/components/single/ButtonKDS";

type AccessibleColor = {
  bg?: string;
  color?: string;
  hoverBg?: string;
  activeBg?: string;
  border?: string;
};

const accessibleColorMap: { [key: string]: AccessibleColor } = {
  kakao: {
    bg: 'kakao.500',
    color: '#1A1A1A',
    hoverBg: 'kakao.600',
    activeBg: 'kakao.700',
  },
  naver: {
    bg: 'naver.500',
    color: '#FFFFFF',
    hoverBg: 'naver.600',
    activeBg: 'naver.700',
  },
  facebook: {
    bg: 'facebook.500',
    color: '#FFFFFF',
    hoverBg: 'facebook.600',
    activeBg: 'facebook.700',
  },
  apple: {
    bg: 'apple.500',
    color: '#FFFFFF',
    hoverBg: 'apple.600',
    activeBg: 'apple.700',
  },
  google: {
    bg: 'google.500',
    color: '#808080',
    hoverBg: 'google.600',
    activeBg: 'google.700',
    border: '#DDDDDD',
  },
  light: {
    bg: '#FFFFFF',
    color: 'netural.500',
    hoverBg: '#F4F4F4',
    activeBg: '#EEEEEE',
    border: 'netural.40',
  },
};

const variantSolid: StyleObjectOrFn = (props) => {
  const { colorScheme: c } = props;

  if (c === 'gray') {
    const bg = 'netural.100';
    return {
      bg,
      _hover: {
        bg: 'netural.100',
        _disabled: {
          bg,
        },
      },
      _active: { bg: 'netural.300' },
    };
  }

  if (c === 'lightGray') {
    const bg = 'netural.10';
    return {
      color: 'black',
      bg,
      border: '1px solid',
      borderColor: 'netural.40',
      _hover: {
        bg: 'netural.100',
        _disabled: {
          bg,
        },
      },
      _active: { bg: 'netural.300' },
    };
  }

  if (c === 'white') {
    const bg = 'white';
    return {
      color: 'netural.500',
      bg,
      border: '1px solid',
      borderColor: 'netural.40',
    };
  }

  if (c === 'red') {
    const bg = 'red.50';
    return {
      color: 'red.700',
      bg,
      _hover: {
        bg: 'red.60',
        _disabled: {
          bg,
        },
      },
      _active: { bg: 'red.100' },
    };
  }

  if (c === 'green') {
    const bg = 'green.600';
    return {
      color: 'white',
      bg,
      _hover: {
        bg: 'green.700',
        _disabled: {
          bg,
        },
      },
      _active: { bg: 'green.800' },
    };
  }

  const {
    bg = `${c}.600`,
    color = 'white',
    hoverBg = `${c}.700`,
    activeBg = `${c}.600`,
    border = `${c}.600`,
  } = accessibleColorMap[c] ?? {};

  const background = bg;
  const borderColor = border;

  return {
    bg: background,
    color: color,
    borderWidth: 1,
    borderColor: borderColor,
    _hover: {
      bg: hoverBg,
      borderColor: hoverBg,
      // _disabled: {
      //   bg: background,
      // },
    },
    _disabled: {
      bg: 'netural.20',
      borderColor: 'netural.40',
      color: 'netural.60',
    },
    _active: { bg: activeBg, borderColor: activeBg },
  };
};

export const Button: ComponentSingleStyleConfig = {
  baseStyle: {
    w: 'fit-content',
    h: 'fit-content',
    padding: '0px 8px',
    gap: '5px',
    borderRadius: '8px',
    border: '1px solid',
    borderColor: 'transparent',
    _focus: { boxShadow: 'none' },
  },
  defaultProps: {
    variant: 'solid',
  },
  variants: {
    solid: variantSolid,

    ...ButtonKDS.variants,
  },
  sizes: {
    lg: {
      h: '40px',
      fontSize: '13px',
      fontWeight: '700',
      lineHeight: '40px',
      letterSpacing: '-0.02em',
      borderRadius: '6px',
      px: '15px',
    },
    md: {
      h: '35px',
      fontSize: '13px',
      fontWeight: '700',
      lineHeight: '35px',
      letterSpacing: '-0.02em',
      borderRadius: '6px',
      px: '10px',
    },
    sm: {
      h: '30px',
      fontSize: '12px',
      fontWeight: '700',
      lineHeight: '30px',
      letterSpacing: '-0.02em',
      borderRadius: '6px',
      px: '10px',
    },
    xs: {
      h: '25px',
      fontSize: '11px',
      fontWeight: '400',
      lineHeight: '25px',
      letterSpacing: '-0.02em',
      borderRadius: '5px',
      px: '8px',
    },

    ...ButtonKDS.sizes,
  },
};
