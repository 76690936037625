import { useRef } from 'react';

import * as S from './Alert.styled';
import Button from '../Button';
import { AlertDialog, AlertDialogHeader, AlertDialogProps } from '@chakra-ui/react';

export interface Props extends Omit<AlertDialogProps, 'leastDestructiveRef'> {
  /** alert의 사이즈 - 기본값 medium (520px) */
  size?: 'small' | 'medium' | 'large';

  /** alert의 타이틀 */
  title?: string;
}

export default function Alert({
  size = 'medium',
  title,
  children,
  onClose,
  ...props
}: Props) {
  const confirmButtonRef = useRef<HTMLButtonElement | null>(null);

  const alertWidth = size === 'small' ? '320px' : (size === 'medium' ? '520px' : '720px');
  const buttonWidth = size === 'small' ? '272px' : '70px';

  return (
    <AlertDialog
      {...props}
      isCentered
      leastDestructiveRef={confirmButtonRef}
      onClose={onClose}
    >
      <S.AlertDialogOverlay />
      <S.AlertDialogContent width={alertWidth}>

        {/* Header - 타이틀 영역 */}
        <AlertDialogHeader padding={typeof title === 'string' ? '24px 24px 12px' : '24px 24px 0'}>
          <S.AlertDialogTitle>
            {title}
          </S.AlertDialogTitle>
        </AlertDialogHeader>

        {/* Body - 컨텐츠 영역 */}
        <S.AlertDialogBody>
          {children}
        </S.AlertDialogBody>

        {/* Footer - 버튼 영역 */}
        <S.AlertDialogFooter>
          <Button
            ref={confirmButtonRef}
            type="button"
            size="large"
            width={buttonWidth}
            colorScheme="green"
            onClick={onClose}
          >
            확인
          </Button>
        </S.AlertDialogFooter>
      </S.AlertDialogContent>
    </AlertDialog>
  );
}
