import { menuAnatomy as parts } from '@chakra-ui/anatomy';
import {
  createMultiStyleConfigHelpers,
  defineStyle,
} from '@chakra-ui/styled-system';

import { ChakraMultiPartComponentType } from '../type';

// const parts = [
//   'button',
//   'list',
//   'item',
//   'groupTitle',
//   'command',
//   'divider',
// ] as const;

const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(parts.keys);

const sizes = {
  sm: definePartsStyle({}),
  md: definePartsStyle({}),
  lg: definePartsStyle({}),
  xl: definePartsStyle({}),
};

const baseStyle = definePartsStyle({
  list: {
    p: '7px 8px',
  },
  item: {
    textStyle: 'caption1_regular',
    borderRadius: '6px',
    _hover: {
      bg: 'netural.10',
    },
  },
});

const variants = {
  default: {},
};

export const Menu = {
  parts,
  baseStyle,
  variants,
  defaultProps: {},
  sizes: {},
};
