import { Global } from '@emotion/react';

const Fonts = () => (
  <Global
    styles={`
    @font-face {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-display: swap;
      src: url('/fonts/Inter-Bold.woff') format("woff");
      unicodeRange: 'U+0041-005A, U+0061-007A, U+0030-0039, U+0020-002F, U+003A-0040, U+005B-0060, U+007B-007E',
    }
  
    @font-face {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-display: swap;
      src: url('/fonts/Inter-Medium.woff') format("woff");
      unicodeRange: 'U+0041-005A, U+0061-007A, U+0030-0039, U+0020-002F, U+003A-0040, U+005B-0060, U+007B-007E',
    }
  
    @font-face {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-display: swap;
      src: url('/fonts/Inter-Regular.woff') format("woff");
      unicodeRange: 'U+0041-005A, U+0061-007A, U+0030-0039, U+0020-002F, U+003A-0040, U+005B-0060, U+007B-007E',
    }
  
    @font-face {
      font-family: 'NotoSans';
      font-style: normal;
      font-weight: 900;
      font-display: swap;
      src: url('/fonts/NotoSans-Black.woff') format("woff");
      unicodeRange: 'U+AC00-D7A3',
    }
  
    @font-face {
      font-family: 'NotoSans';
      font-style: normal;
      font-weight: 700;
      font-display: swap;
      src: url('/fonts/NotoSans-Bold.woff') format("woff");
      unicodeRange: 'U+AC00-D7A3',
    }
  
    @font-face {
      font-family: 'NotoSans';
      font-style: normal;
      font-weight: 500;
      font-display: swap;
      src: url('/fonts/NotoSans-Medium.woff') format("woff");
      unicodeRange: 'U+AC00-D7A3',
    }
  
    @font-face {
      font-family: 'NotoSans';
      font-style: normal;
      font-weight: 400;
      font-display: swap;
      src: url('/fonts/NotoSans-Regular.woff') format("woff");
      unicodeRange: 'U+AC00-D7A3',
    }
  
    @font-face {
      font-family: 'NotoSans';
      font-style: normal;
      font-weight: 300;
      font-display: swap;
      src: url('/fonts/NotoSans-Light.woff') format("woff");
      unicodeRange: 'U+AC00-D7A3',
    }
  
    @font-face {
      font-family: 'NotoSans';
      font-style: normal;
      font-weight: 100;
      font-display: swap;
      src: url('/fonts/NotoSans-Thin.woff') format("woff");
      unicodeRange: 'U+AC00-D7A3',
    }
  `}
  />
);

export default Fonts;
