import { AppProps } from 'next/app';
import Head from 'next/head';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import dayjs from 'dayjs';

import * as Sentry from '@sentry/nextjs';

import { ThemeProvider, useColorMode, useTheme } from '@chakra-ui/react';

import { mode } from '@theme/foundations/colors';

import withAppProvider from 'contexts/app/app.provider';
import ModalsProvider from 'contexts/modal/modal.provider';
import { withGlobalModalHandlerContext } from 'contexts/modal/useGlobalModalHandler.context';
import 'dayjs/locale/ko';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import objectSupport from 'dayjs/plugin/objectSupport';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import 'overlayscrollbars/overlayscrollbars.css';
import ModalExitConfirmProvider from 'provider/ModalExitConfirmProvider';
import PageExitConfirmProvider from 'provider/PageExitConfirmProvider';
import ModalProvider from 'provider/ModalProvider';
import GlobalStyles from 'styles/GlobalStyles';
import SentryErrorFallbackView from 'containers/ErrorView/SentryErrorFallbackView';
import ChannelTalkBoot from '@components/ChannelTalkBoot';
import 'styles/overlayscrolbars/custom.css';

dayjs.extend(objectSupport);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(weekOfYear);
dayjs.locale('ko')

if (process.env.NEXT_PUBLIC_API_MOCKING === 'enabled') {
  import('../mocks');
}

function MyApp({ Component, pageProps }: AppProps) {
  const theme = useTheme();
  const methods = useForm();
  const { colorMode } = useColorMode();
  const queryClient =  useQueryClient();

  return (
    <>
      <Head>
        <meta name="viewport" content="width=1600" />
      </Head>

      <Sentry.ErrorBoundary
        fallback={SentryErrorFallbackView}
        onReset={() => queryClient.resetQueries()}
      >
        <ThemeProvider
          theme={{ ...theme, colors: { ...theme.colors, ...mode[colorMode] } }}
        >
          <GlobalStyles />
          <FormProvider {...methods}>
            <ModalsProvider>
              <PageExitConfirmProvider>
                <ModalExitConfirmProvider>
                  <ChannelTalkBoot />
                  <Component {...pageProps} />
                  <ModalProvider />
                </ModalExitConfirmProvider>
              </PageExitConfirmProvider>
            </ModalsProvider>
            <ReactQueryDevtools
              initialIsOpen={false}
              toggleButtonProps={{
                style: {
                  width: '20px',
                  height: '20px',
                  margin: '20px 0',
                },
              }}
            />
          </FormProvider>
        </ThemeProvider>
      </Sentry.ErrorBoundary>
    </>
  );
}

export default withAppProvider(withGlobalModalHandlerContext(MyApp));
