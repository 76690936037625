/* eslint-disable @typescript-eslint/no-explicit-any */
import { StyleFunctionProps, mode } from '@chakra-ui/theme-tools';

const styles = {
  global: (props: StyleFunctionProps) => ({
    body: {
      fontFamily: 'Inter, NotoSans',
      color: mode('var(--N700)', '#FFFFFF')(props),
      bg: mode('#FFFFFF', '#363636')(props),
    },
    input: {
      fontSize: ['16px', '14px', '15px'],
    },

    /**
     * 인쇄 가로 버전 폰트 사이즈
     *
     * b => <Text as="b" />
     * p => <Text />
     */
    "@media print": {
      "page" :{
        size: "landscape",
        margin: 0,
      },
      ".landscape": {
        p : {
          fontSize: "16px",
        },
        b : {
          fontSize: "15px",
        }
      },
    },

    '#__next': {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
    },
  }),
};

export default styles;
