import { ComponentSingleStyleConfig } from "@chakra-ui/react";

const defaultFontStyle = {
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '150%',
  letterSpacing: '-0.02em',
};

const eventStyle = {
  _hover: { _after: { background: 'var(--TD004)' } },
  _active: { _after: { background: 'var(--TD004)' } },
  _disabled: { 
    opacity: 1, 
    border: '1px solid var(--TL054)',
    _after: { background: 'var(--TL054)' } },
}

export const ButtonKDS: ComponentSingleStyleConfig = {
  variants: {
    gray: {
      color: 'var(--N700)',
      bg: 'var(--N20)',
      ...eventStyle,
    },
    lightGray: {
      color: 'black',
      bg: 'neutral.10',
      border: '1px solid',
      borderColor: 'neutral.40',
      _hover: { bg: 'neutral.100', _disabled: { bg: 'neutral.10' } },
      _active: { bg: 'neutral.300' }
    },
    secondaryGray: {
      color: 'var(--N500)',
      bg: 'var(--N20)',
      p: '8px',
      border: '1px solid var(--N20)',
      ...eventStyle,
    },
    white: {
      color: 'var(--N700)',
      bg: 'var(--white)',
      border: '1px solid',
      borderColor: 'var(--N40)'
    },
    red: {
      color: 'red.700',
      bg: 'red.50',
      _hover: { bg: 'red.60', _disabled: { bg: 'red.50' } },
      _active: { bg: 'red.100' }
    },
    green: {
      color: 'white',
      bg: 'green.600',
      _hover: { bg: 'green.700', _disabled: { bg: 'green.600' } },
      _active: { bg: 'green.800' }
    },
    kakao: {
      bg: 'kakao.500',
      color: '#1A1A1A',
      borderWidth: 1,
      borderColor: 'kakao.600',
      _hover: { bg: 'kakao.600', borderColor: 'kakao.600' },
      _disabled: { bg: 'neutral.20', borderColor: 'neutral.40', color: 'neutral.60' },
      _active: { bg: 'kakao.700', borderColor: 'kakao.700' }
    },
    naver: {
      bg: 'naver.500',
      color: 'white',
      borderWidth: 1,
      borderColor: 'naver.600',
      _hover: { bg: 'naver.600', borderColor: 'naver.600' },
      _disabled: { bg: 'neutral.20', borderColor: 'neutral.40', color: 'neutral.60' },
      _active: { bg: 'naver.700', borderColor: 'naver.700' }
    },
    facebook: {
      bg: 'facebook.500',
      color: 'white',
      borderWidth: 1,
      borderColor: 'facebook.600',
      _hover: { bg: 'facebook.600', borderColor: 'facebook.600' },
      _disabled: { bg: 'neutral.20', borderColor: 'neutral.40', color: 'neutral.60' },
      _active: { bg: 'facebook.700', borderColor: 'facebook.700' }
    },
    apple: {
      bg: 'apple.500',
      color: 'white',
      borderWidth: 1,
      borderColor: 'apple.600',
      _hover: { bg: 'apple.600', borderColor: 'apple.600' },
      _disabled: { bg: 'neutral.20', borderColor: 'neutral.40', color: 'neutral.60' },
      _active: { bg: 'apple.700', borderColor: 'apple.700' }
    },
    google: {
      bg: 'google.500',
      color: '#808080',
      borderWidth: 1,
      borderColor: '#DDDDDD',
      _hover: { bg: 'google.600', borderColor: 'google.600' },
      _disabled: { bg: 'neutral.20', borderColor: 'neutral.40', color: 'neutral.60' },
      _active: { bg: 'google.700', borderColor: 'google.700' }
    },
    light: {
      bg: 'white',
      color: 'netural.500',
      borderWidth: 1,
      borderColor: 'netural.40',
      _hover: { bg: 'var(--TD004)', borderColor: 'var(--TD004)' },
      _disabled: { bg: 'neutral.20', borderColor: 'neutral.40', color: 'neutral.60' },
      _active: { bg: 'var(--TD004)', borderColor: 'var(--TD004)' }
    },
    primary: { ...eventStyle, background: 'var(--G600)', color: 'white' },
    primaryBlue: { ...eventStyle, background: 'var(--B600)', color: 'white' },
    secondary: { ...eventStyle, background: 'var(--G50)', color: 'var(--G600)' },
    secondaryBlue: { ...eventStyle, background: 'var(--B50)', color: 'var(--B600)' },
    default: { ...eventStyle, background: 'var(--N20)', color: 'var(--N700)' },
    outline: {
      background: 'white',
      color: 'var(--N700)',
      borderWidth: 1,
      borderColor: 'var(--N40)',
      _hover: { background: 'white', borderColor: 'white', },
      _active: { background: 'white', borderColor: 'white', },
      _disabled: eventStyle._disabled,
    },
    subtle: {
      background: 'white',
      color: 'var(--N700)',
      _active: eventStyle._active,
    },
    danger: {
      ...eventStyle,
      background: 'var(--R50)',
      color: 'var(--R500)',
      borderWidth: 0,
    },
    dangerSolid: {
      ...eventStyle,
      background: 'var(--R500)',
      color: 'white',
      borderWidth: 0,
    },
    outlineGreen: { ...eventStyle, background: 'var(--G50)', color: 'var(--G600)', outline: '1px solid var(--G600)', },
    outlineGray: { ...eventStyle, background: 'white', color: 'var(--N200)', borderWidth: 1, borderColor: 'var(--N40)', },
  },
  sizes: {
    xxsmall: {
      ...defaultFontStyle,
      height: '24px',
      borderRadius: '5px',
      fontSize: '13px',
      lineHeight: 1,
      padding: '0 8px',
      _hover: { _after: { borderRadius: '5px' } },
      _active: { _after: { borderRadius: '5px' } },
      _disabled: { _after: { borderRadius: '5px' } },
    },
    xsmall: {
      ...defaultFontStyle,
      height: '32px',
      borderRadius: '6px',
      fontSize: '13px',
      lineHeight: 1,
      padding: '0 12px',
      _hover: { _after: { borderRadius: '6px' } },
      _active: { _after: { borderRadius: '6px' } },
      _disabled: { _after: { borderRadius: '6px' } },
    },
    small: {
      ...defaultFontStyle,
      height: '36px',
      borderRadius: '7px',
      fontSize: '13px',
      lineHeight: 1,
      padding: '0 12px',
      _hover: { _after: { borderRadius: '7px' } },
      _active: { _after: { borderRadius: '7px' } },
      _disabled: { _after: { borderRadius: '7px' } },
    },
    medium: {
      ...defaultFontStyle,
      height: '40px',
      borderRadius: '8px',
      fontSize: '15px',
      lineHeight: 1,
      padding: '0 16px',
      _hover: { _after: { borderRadius: '8px' } },
      _active: { _after: { borderRadius: '8px' } },
      _disabled: { _after: { borderRadius: '8px' } },
    },
    large: {
      ...defaultFontStyle,
      height: '48px',
      borderRadius: '10px',
      fontSize: '16px',
      lineHeight: 1,
      padding: '0 12px',
      _hover: { _after: { borderRadius: '10px' } },
      _active: { _after: { borderRadius: '10px' } },
      _disabled: { _after: { borderRadius: '10px' } },
    },
    xlarge: {
      ...defaultFontStyle,
      height: '52px',
      borderRadius: '11px',
      fontSize: '17px',
      lineHeight: 1,
      padding: '0 20px',
      _hover: { _after: { borderRadius: '11px' } },
      _active: { _after: { borderRadius: '11px' } },
      _disabled: { _after: { borderRadius: '11px' } },
    },
    xxlarge: {
      ...defaultFontStyle,
      height: '56px',
      borderRadius: '12px',
      fontSize: '17px',
      lineHeight: 1,
      padding: '0 24px',
      _hover: { _after: { borderRadius: '12px' } },
      _active: { _after: { borderRadius: '12px' } },
      _disabled: { _after: { borderRadius: '12px' } },
    },
  }
}
