export const PopoverKDS = {
  variants: {
    default: {
      content: {
        _focus: {
          boxShadow: 'none',
        }
      }
    },
  }
}
