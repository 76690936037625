import {
  ModalBodyProps,
  ModalContentProps,
  ModalFooterProps,
  ModalHeaderProps,
} from '@chakra-ui/react';

export const modalContent: ModalContentProps = {
  minWidth: '520px',
  padding: '24px',
  borderRadius: '12px',
  border: '1px solid var(--N40)',
  background: 'white',
  /* S20 (KDS) */
  boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.15)',
};

export const modalHeader: ModalHeaderProps = {
  padding: 0,

  color: 'var(--N700)',
  fontSize: '19px',
  fontWeight: 700,
  lineHeight: 1.4,
  letterSpacing: '-0.01em',

  whiteSpace: 'pre',
};

export const modalBody: ModalBodyProps = {
  padding: 0,
  margin: '12px 0 0 0',

  fontSize: '15px',
  fontWeight: '400',
  lineHeight: 1.5,
  letterSpacing: '-0.02em',
};

export const modalFooter: ModalFooterProps = {
  padding: 0,
  margin: '24px 0 0 0',
};
