/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, useState, ReactNode, } from 'react';
import PageExitConfirmModal from '@components/v2/PageExitConfirmModal';
import { PageExitConfirmContext } from './PageExitConfirmProvider';

interface Props {
  children: ReactNode;
}

const defaultConfirmContext = {
  onOpenModal: () => { },
  onCloseModal: () => { },
  isClickedConfirmButton: false,
  setIsClickedConfirmButton: () => { }
}

export const ModalConfirmContext = createContext<PageExitConfirmContext>(defaultConfirmContext);

const ModalExitConfirmProvider = ({
  children
}: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isClickedConfirmButton, setIsClickedConfirmButton] = useState<boolean>(false);

  const confirmValue = {
    onOpenModal: () => setIsOpen(true),
    onCloseModal: () => {
      setIsClickedConfirmButton(true);
      setIsOpen(false);
    },
    isClickedConfirmButton,
    setIsClickedConfirmButton
  }

  const handleConfirmButton = () => {
    setIsClickedConfirmButton(true); // 외부에서 확인 버튼이 클릭 됐는지 체크하기 위한 용도
    setIsOpen(false);
  }

  const handleCloseButton = () => {
    setIsOpen(false);
  }

  return (
    <ModalConfirmContext.Provider
      value={confirmValue}
    >
      <PageExitConfirmModal
        isOpen={isOpen}
        onConfirm={handleConfirmButton}
        onCancel={handleCloseButton}
        onClose={handleCloseButton}
      />
      {children}
    </ModalConfirmContext.Provider>
  );
};

export default ModalExitConfirmProvider;