var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"483771703559aef4c526573c273da58e0231fe27"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import { SentryKidsnoteIntegration } from '@kidsnote/toolkit/sentry';
import * as Sentry from '@sentry/nextjs';

const sentryDsn = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  enabled: !!sentryDsn,
  dsn: sentryDsn,
  release: process.env.GIT_COMMIT || process.env.NEXT_APP_SENTRY_RELEASE,
  environment: process.env.DEPLOY_ENV || process.env.NEXT_PUBLIC_SENTRY_ENV,
  tracesSampleRate: 0.01,
  integrations: [
    new SentryKidsnoteIntegration(),
  ],
  denyUrls: [
    /^https:\/\/www\.googletagmanager\.com/, // 구글 태그매니저
    /^https:\/\/translate\.googleapis\.com/, // 구글 번역
    /^https:\/\/script\.hotjar\.com/, // Hotjar

    // Chrome extensions
    /^chrome-extension:\/\//,
    /^chrome:\/\//i,

    // Local
    /^file:\/\/\//,
  ],
  ignoreErrors: [
    // Safari extensions
    /safari-web-extension/i,
    /webkit-masked-url/i,

    // https://github.com/juggle/resize-observer/issues/103
    'ResizeObserver loop completed with undelivered notifications.',

    // IntersectionObserver
    'Can\'t find variable: IntersectionObserver',

    // 400번대 오류는 서버에서 로그를 수집하고 있음
    'Request failed with status code 400',
    'Request failed with status code 404',

    // 409 오류의 경우 사용자 중복 전송을 막기 위한 처리이므로 수집 불필요.
    'Request failed with status code 409',

    // 안드로이드 앱에서 발생되는 오류 무시
    'setLayerTypeSoftwareCallback is not defined',
  ],
  normalizeDepth: 5,
});
