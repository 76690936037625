import type { CaptureContext } from '@sentry/types';

export class ForbiddenError extends Error {
  statusCode: number;

  captureContext: CaptureContext;

  constructor(message: string, captureContext: CaptureContext = {}) {
    super(message);

    // Terser에서 Minify되는 과정에서 오류 클래스의 이름이 바뀌므로 String으로 직접 주입한다.
    this.name = 'ForbiddenError';
    this.statusCode = 403;

    // 오류에 대한 Stack을 표시
    if (Error.captureStackTrace) {
      // V8 엔진 전용
      Error.captureStackTrace(this, ForbiddenError);
    }

    this.captureContext = {
      level: 'warning',
      ...captureContext,
    };
  }
}

export default ForbiddenError;
