import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import {
  BedsoresRiskDetailType,
  CognitiveFunctionDetailType,
  FallRiskDetailType,
  NeedAssessmentDetailType,
} from 'generated/apis/@types/data-contracts';

interface commonStateType {
  id: 0;
  isTemp: boolean;
  created: string;
  reason: 'N' | 'R' | 'C';
}

interface lastEvaluationType {
  fall: number | null;
  bed: number | null;
  cognitive: number | null;
  need: number | null;
}

export interface evaluationType {
  formState: boolean;
  commonState: commonStateType;
  // TODO: type 지정
  // fallRiskData: any;
  fallRiskData: Partial<FallRiskDetailType>;
  bedsoresRiskData: Partial<BedsoresRiskDetailType>;
  recognitionData: Partial<CognitiveFunctionDetailType>;
  needAssessmentData: Partial<NeedAssessmentDetailType>;
  lastEvaluation: lastEvaluationType;
}

const initialState: evaluationType = {
  formState: false,
  commonState: {
    id: 0,
    isTemp: false,
    created: '',
    reason: 'N',
  },
  fallRiskData: {
    id: undefined,
    evaluationId: undefined,
    employee: undefined,
    modifiedDate: undefined,
    created: undefined,
    isTemp: true,
    reason: undefined,
    age: null,
    mentality: null,
    defecation: null,
    experience: null,
    activity: null,
    balance: null,
    medicine: null,
    totalPoint: null,
    judgment: undefined,
    memo: '',
  },
  bedsoresRiskData: {
    id: undefined,
    evaluationId: undefined,
    employee: undefined,
    modifiedDate: undefined,
    created: undefined,
    isTemp: true,
    reason: undefined,
    cognitionDegree: null,
    moisture: null,
    activity: null,
    movement: null,
    nutritiveCondition: null,
    frictionResponse: null,
    judgment: undefined,
    memo: '',
  },
  recognitionData: {
    id: 0,
    evaluationId: 0,
    employee: { id: 0, name: '', sign:
  {
    id: 0,
    accessKey: '',
    originalFileName: '',
    fileSize: 0,
    width: 0,
    height: 0,
    original: '',
    large: '',
    small: '',
  } },
    created: '',
    modifiedDate: undefined,
    isTemp: true,
    reason: 'N',
    education: null,
    educationPeriod: null,
    evaluationPlace: null,
    isDementiaDiagnosis: false,
    orientation1: null,
    orientation2: null,
    orientation3: null,
    orientation4: null,
    orientation5: null,
    memory1: [],
    memory2: [],
    attention1: null,
    attention2: null,
    attention3: null,
    visuospatialAbility1: null,
    executiveFunction1: null,
    executiveFunction2: null,
    executiveFunction3: null,
    executiveFunction4: null,
    memory3: null,
    memory4: null,
    memory5: null,
    memory6: null,
    memory7: null,
    languageFunction1: '',
    languageFunction2: null,
    languageFunction3: '',
    languageFunction4: null,
    languageFunction5: '',
    languageFunction6: null,
    languageFunction7: '',
    languageFunction8: null,
    executiveFunction6: '',
    executiveFunction7: null,
    orientationPoint: null,
    attentionPoint: null,
    visuospatialAbilityPoint: null,
    executiveFunctionPoint: null,
    memoryPoint: null,
    languageFunctionPoint: null,
    totalPoint: undefined,
    judgment: undefined,
    memo: '',
  },
  needAssessmentData: {
    id: 0,
    evaluationId: 0,
    employee: { id: 0, name: '', sign:
    {
      id: 0,
      accessKey: '',
      originalFileName: '',
      fileSize: 0,
      width: 0,
      height: 0,
      original: '',
      large: '',
      small: '',
    } },
    isTemp: true,
    reason: 'N',
    modifiedDate: '',
    stepone: {
      id: 0,
      needAssessmentId: 0,
      isTemp: true,
      height: null,
      weight: null,
      item1: null,
      item2: null,
      item2Etc: null,
      item3: null,
      item4: null,
      item4Etc: '',
      item5: null,
      item6: null,
      item7: null,
      judgmentBasis: null,
    },
    steptwo: {
      id: 0,
      needAssessmentId: 0,
      isTemp: true,
      pastDiagnosis: null,
      currentDiagnosis: null,
      item1: [],
      item1Etc: null,
      item2: [],
      item2Etc: null,
      item3: [],
      item3Etc: null,
      item4: [],
      item4Etc: null,
      item5: [],
      item5Etc: null,
      item6: [],
      item6Etc: null,
      item7: [],
      item7Etc: '',
      judgmentBasis: '',
    },
    stepthree: {
      id: 0,
      needAssessmentId: 0,
      isTemp: true,
      item1: null,
      item2: null,
      item3: null,
      item4: null,
      item5: null,
      item6: null,
      item7: null,
      item8: null,
      item9: null,
      item10: null,
      judgmentBasis: '',
    },
    stepfour: {
      id: 0,
      needAssessmentId: 0,
      isTemp: true,
      item1: false,
      item2: false,
      item3: false,
      item4: false,
      item5: false,
      item6: false,
      item7: false,
      item8: false,
      judgmentBasis: '',
    },
    stepfive: {
      id: 0,
      needAssessmentId: 0,
      isTemp: true,
      hearingCondition: null,
      communication: null,
      pronunciationAbility: null,
      judgmentBasis: '',
    },
    stepsix: {
      id: 0,
      needAssessmentId: 0,
      isTemp: true,
      item1: [],
      item1Etc: '',
      item2: null,
      item2M: null,
      item2W: null,
      item3: null,
      item4: null,
      item4Etc: '',
      item5: null,
      item6: null,
      item7: null,
      item7Etc: '',
      judgmentBasis: '',
    },
    stepseven: {
      id: 0,
      needAssessmentId: 0,
      isTemp: true,
      item1: null,
      item2: null,
      item3: null,
      item4: null,
      item4Etc: null,
      item5: [],
      judgmentBasis: null,
    },
    stepeight: {
      id: 0,
      needAssessmentId: 0,
      isTemp: true,
      judgmentBasis: null,
    },
    stepnine: {
      id: 0,
      needAssessmentId: 0,
      isTemp: true,
      review: '',
    },
    stepten: {
      id: 0,
      needAssessmentId: 0,
      isTemp: true,
      item1: [],
      item1Etc: null,
      item2: [],
      item2Etc: null,
      item3: [],
      item4: [],
      item4Part: null,
      item4Etc: null,
      item5: [],
      item5Etc: null,
      item6: null,
      item7: [],
      item7Etc: null,
      item8Bool: null,
      item8Tool: null,
      item9: [],
      item9Etc: null,
      item10: [],
      item10Etc: null,
      generalOpinion: '',
    },
  },
  lastEvaluation: {
    fall: null,
    bed: null,
    cognitive: null,
    need: null,
  },
};

export const evaluationSlice = createSlice({
  name: 'EVALUATION',
  initialState,
  reducers: {
    setFallEvaluationData: (
      state,
      action: PayloadAction<Partial<FallRiskDetailType>>,
    ) => {
      state.fallRiskData = action.payload;
    },
    setBedsoresEvaluationData: (
      state,
      action: PayloadAction<Partial<BedsoresRiskDetailType>>,
    ) => {
      state.bedsoresRiskData = action.payload;
    },
    setCommonData: (state, action: PayloadAction<commonStateType>) => {
      state.commonState = action.payload;
    },
    setFormState: (state, action: PayloadAction<boolean>) => {
      state.formState = action.payload;
    },
    setRecognitionData: (
      state,
      action: PayloadAction<Partial<CognitiveFunctionDetailType>>,
    ) => {
      state.recognitionData = action.payload;
    },

    setNeedAssessmentData: (
      state,
      action: PayloadAction<Partial<NeedAssessmentDetailType>>,
    ) => {
      state.needAssessmentData = action.payload;
    },
    setLastEvaulation: (state, action: PayloadAction<lastEvaluationType>) => {
      state.lastEvaluation = action.payload;
    },

    setClear: () => {
      return initialState;
    },
  },
});

export const {
  actions: evaluationSliceActions,
  reducer: evaluationSliceReducer,
} = evaluationSlice;

export default evaluationSlice;
