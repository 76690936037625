const KDS_TH_TD_DEFAULT_STYLE = {
  borderColor: 'var(--N40)',
  borderRightWidth: '1px',
  borderBottomWidth: '1px',
  padding: 0,

  color: 'var(--N700)',
  fontSize: '14px',
  lineHeight: 1.5,
  letterSpacing: '-0.02em',
  textAlign: 'left',
  verticalAlign: 'middle',
};

const KDS_PRINT_TABLE_STYLE = {
  tableLayout: 'fixed',
  width: '100%',
  minWidth: '100%',
  maxWidth: 'none',
  marginTop: '3.175mm;',
  borderCollapse: 'collapse',
  borderSpacing: 0,
  color: 'var(--N700)',
}

const KDS_PRINT_TABLE_CELL_STYLE = {
  padding: 0,
  border: '1px solid var(--N40)',
  boxSizing: 'border-box',
  fontSize: '2.91mm',
  lineHeight: '1.5',
  letterSpacing: '-.01em',
};

const KDSRound = {
  table: {
    fontVariantNumeric: 'normal',
    borderWidth: '1px',
    borderRadius: '8px',
    overflow: 'hidden',
    borderCollapse: 'separate',
    borderSpacing: 0,
  },
  thead: {
    borderTopWidth: 0,
    borderColor: 'var(--N40)',
  },
  tr: {
    height: '40px',
    '&:last-of-type th': {
      borderBottomWidth: '0px',
    },
    '&:first-of-type td': {
      borderTopWidth: '0px',
    },
    '&:last-of-type td': {
      borderBottomWidth: '0px',
    },
  },
  th: {
    ...KDS_TH_TD_DEFAULT_STYLE,

    backgroundColor: 'var(--N20)',

    fontWeight: 500,
  },
  td: {
    ...KDS_TH_TD_DEFAULT_STYLE,

    '&:last-of-type': {
      borderRightWidth: '0px',
    },

    fontWeight: 400,
  },
  tfoot: {
    tr: {
      '&:last-of-type': {
        th: { borderBottomWidth: 0 },
      },
    },
  },
};

const KDSPrintBasic = {
  table: {
    ...KDS_PRINT_TABLE_STYLE,
    tbody: {
      tr: {
        height: 'auto',

        th: {
          ...KDS_PRINT_TABLE_CELL_STYLE,
          fontWeight: 700,
          backgroundColor: 'var(--N20)',
        },
        td: {
          ...KDS_PRINT_TABLE_CELL_STYLE,
          fontWeight: 400,
          textAlign: 'left',
          backgroundColor: 'var(--white)',
        }
      }
    }
  },
};

const KDSTableServicePlan = {
  table: {
    ...KDSRound.table,
    tr: {
      '&:first-of-type th:first-of-type': {
        borderLeftWidth: 0,
      },
    },
    th: {
      textAlign: 'center',
      fontSize: '14px',
      fontWeight: 500,
      color: 'var(--N200)',
      borderColor: 'var(--N40)',
      background: 'var(--N20)',
      borderBottomWidth: 1,
      borderLeftWidth: 1,
    },
    tbody: {
      tr: {
        td: {
          padding: 0,
          borderBottomWidth: 1,
          verticalAlign: 'middle',
          borderLeftWidth: 1,
          borderRightWidth: 0,
          '&:first-of-type': {
            borderLeftWidth: 0,
          },
        },
        '&:last-of-type td': {
          borderBottomWidth: 0,
        },
      }
    }
  },
};

const KDSCalendarTable = {
  table: {
    borderColor: 'var(--N40)',
    borderRightWidth: 1,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    tr: {
      td: {
        borderColor: 'var(--N40)',
        borderRightWidth: 1,
      }
    },
    tbody: {
      tr: {
        td: {
          borderColor: 'var(--N40)',
          borderRightWidth: 1,
          borderTopWidth: 1,
          borderBottomWidth: 1,

          padding: 0,
          color: 'var(--N500)',
          fontFamily: 'Inter',
          fontSize: '13px',
          fontStyle: 'normal',
          fontWeight: 500,
        },
      }
    }
  },
};

export const TableKDS = {
  variants: {
    /*
     KDSDefault 가 KDSRound 아니어도 됨. KDSRound 는 ColumnsGrid 를 기반으로 생성됨
     스타일이 변경되면 KDSDefault 말고 추가 해서 사용 하는걸 추천함.
      */
    KDSDefault: {
      ...KDSRound,
      table: {
        ...KDSRound.table,
      },
      th: {
        ...KDSRound.th,
        '&:last-of-type': {
          borderRightWidth: 0,
        },
      },
    },
    KDSPrintDefault: {
      ...KDSPrintBasic,
      table: {
        ...KDSPrintBasic.table,
      },
    },
    KDSRound,
    KDSPrintBasic,
    KDSTableServicePlan,
    KDSCalendarTable
  }
};
