import {
  AlertDialogBody as BaseAlertDialogBody,
  AlertDialogFooter as BaseAlertDialogFooter,
  AlertDialogContent as BaseAlertDialogContent,
  AlertDialogOverlay as BaseAlertDialogOverlay,
  Text as BaseText,
} from '@chakra-ui/react';
import styled from '@emotion/styled';

export const AlertDialogOverlay = styled(BaseAlertDialogOverlay)`
  background: var(--TD030);
`;

export const AlertDialogContent = styled(BaseAlertDialogContent)`
  max-width: none;
  border-radius: 12px;
  box-shadow: 0px 2px 8px var(--TD015);
`;

export const AlertDialogTitle = styled(BaseText)`
  font-size: 19px;
  letter-spacing: -0.19px;
  white-space: pre-wrap;
`;

export const AlertDialogBody = styled(BaseAlertDialogBody)`
  padding: 0 24px;
  font-size: 15px;
  letter-spacing: -0.3px;
`;

export const AlertDialogFooter = styled(BaseAlertDialogFooter)`
  padding: 24px;
`;
