import { ComponentSingleStyleConfig } from '@chakra-ui/theme';

const defaultFontStyle = {
  color: 'var(--N700)',
  fontStyle: 'normal',
  fontSize: '15px',
  fontWeight: 400,
  lineHeight: '150%',
  letterSpacing: '-0.02em',
};

export const TextareaKDS: ComponentSingleStyleConfig = {
  sizes: {
    // TODO: 추후에 디자인 가이드대로 추가하기
  },
  variants: {
    default: {
      ...defaultFontStyle,
      borderRadius: '8px',
      border: '1px solid var(--N40)',
      bg: 'var(--white)',

      _placeholder: {
        color: 'var(--N60)',
        fontFamily: 'Noto Sans KR',
        fontSize: '15px',
        fontStyle: 'normal',
        fontWeight: 400,
      },
    }
  },
};
