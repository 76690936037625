import axios from 'axios';
import { createClient } from '@kidsnote/openapi-axios';

import { CONFIG } from '@config';
import { getToken } from '@utils/localStorage/token';
import type { paths as dcPaths } from 'types/generated/dc';

import ForbiddenError from 'lib/errors/ForbiddenError';
import NotFoundError from 'lib/errors/NotFoundError';
import UnauthorizedError from 'lib/errors/UnauthorizedError';
// import ServiceUnavailableError from 'lib/errors/ServiceUnavailableError';

const client = axios.create({
  baseURL: `${CONFIG.API_BASE_URL}/v1/dc`,
  /**
   * https://github.com/axios/axios/blob/main/README.md#request-config
   * `timeout` specifies the number of milliseconds before the request times out.
   * If the request takes longer than `timeout`, the request will be aborted.
   */
  timeout: 0,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
  paramsSerializer: function (paramObj) {
    const params = new URLSearchParams();

    Object.keys(paramObj).forEach((key) => {
      const value = paramObj[key];
      if (value === undefined) return;
      if (Array.isArray(value)) {
        value.forEach((v, idx) => params.append(key, v));
        return;
      }
      params.append(key, value);
    });
    return params.toString();
  },
});

const setAuthHeader = (token: string) => {
  if (token) {
    client.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }
};

const unsetAuthHeader = () => {
  delete client.defaults.headers.common['Authorization'];
};

client.interceptors.request.use(
  async (config) => {
    const token = await getToken();
    const isAccess = !!token && !!token.access;
    if (isAccess) setAuthHeader(token.access as string);
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

client.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    // https://axios-http.com/docs/cancellation
    if (axios.isCancel(error)) {
      return Promise.reject(error);
    }

    // https://www.figma.com/file/CsFvbFhPD0V8QrXgMG2Ob4/%ED%8C%A8%EB%B0%80%EB%A6%AC%EC%BC%80%EC%96%B4-%EC%A3%BC%EC%95%BC%EA%B0%84%EB%B3%B4%ED%98%B8-ERP-(%EB%82%B4%EB%B6%80%EA%B0%9C%EB%B0%9C)?type=design&node-id=12619-293549&mode=dev
    if (error?.config?.method === 'get') {
      const { status } = error?.response ?? {};

      if (status === 401) {
        throw new UnauthorizedError('사용자 자격 증명이 유효하지 않습니다.');
      }
  
      if (status === 403) {
        throw new ForbiddenError('접근 권한이 없습니다.');
      }
  
      if (status >= 400 && status < 500) {
        const errorMessage = error.response?.data ? JSON.stringify(error.response.data) : '클라이언트 에러가 발생했습니다.';
        throw new NotFoundError(errorMessage);
      }
    }

    // FIXME 미들웨어에서 처리 필요
    // if (status === 503) {
    //   throw new ServiceUnavailableError('서버 점검 중입니다.');
    // }

    const errorMessage = String(Object.values(error?.response?.data)?.[0]);

    if (errorMessage === '[object Object]') {
      const responseData = Object.values(error?.response?.data)?.[0] as Object;
      const errorType = Object.values(responseData)?.[0]?.[0];
      return Promise.reject(errorType);
    } else if (!errorMessage?.startsWith('<') || errorMessage?.length > 100) {
      return Promise.reject(errorMessage);
    }

    return Promise.reject(error);
  },
);

export { setAuthHeader, unsetAuthHeader };
export default createClient<dcPaths>(client);
