import { StyleObjectOrFn } from '@chakra-ui/styled-system';
import { ComponentSingleStyleConfig } from '@chakra-ui/theme';

import { TextareaKDS } from '@theme/components/single/TextareaKDS';

const solid: StyleObjectOrFn = (props) => {
  return {
    p: '10px',
    bg: 'white',
    border: '1px solid',
    borderColor: 'netural.40',
    fontSize: '13px',
    color: 'netural.500',
    _hover: {
      borderColor: 'netural.50',
    },
    _selected: {
      borderColor: 'netural.40',
    },
    _focus: {
      borderColor: 'green.300',
    },
  };
};

export const Textarea: ComponentSingleStyleConfig = {
  baseStyle: {
    resize: 'none',
    _placeholder: {
      textStyle: 'caption1_regular',
      color: '#C2C8CF',
    },
  },
  defaultProps: {
    variant: 'solid',
  },
  sizes: {},
  variants: {
    solid,

    ...TextareaKDS.variants
  },
};
