import { useCallback } from 'react';
import * as ChannelService from '@channel.io/channel-web-sdk-loader';

import { useChannelTalkStatus } from 'lib/state/store';

import { useAlert } from 'provider/store';

function isProdEnv() {
  return typeof window.location !== 'undefined' && window.location?.hostname?.startsWith('familycare.');
}

export default function useChannelTalk() {
  const setAlert = useAlert((store) => store.setAlert);
  const { status, error } = useChannelTalkStatus((store) => store);

  const handleChannelTalkError = useCallback(() => {
    setAlert({ title: '일시적으로 연결이 원활하지 않습니다.\n잠시 후 다시 시도해 주세요.' });
  }, [setAlert]);

  const handleShowMessenger = useCallback(() => {
    if (status === 'logout') {
      const channelTalkUrl = (isProdEnv() ? process.env.NEXT_PUBLIC_CHANNEL_IO_URL : process.env.NEXT_PUBLIC_CHANNEL_IO_DEV_URL)
        || 'https://kidsnote.channel.io';
      window.open(channelTalkUrl, '_blank');
      return;
    }

    if (error) {
      handleChannelTalkError();
      return;
    }

    ChannelService.showMessenger();
  }, [status, error, handleChannelTalkError]);

  return {
    handleShowMessenger,
    handleChannelTalkError,
  };
}
