import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import {
  CounselingCreateRetrieveUpdateType,
  ServiceProvisionReflectionCreateRetrieveUpdateType,
} from './../../generated/apis/@types/data-contracts';

export interface counselingStateType {
  counselingMonthsData: Partial<CounselingCreateRetrieveUpdateType>;
  counselingDetailData: Partial<CounselingCreateRetrieveUpdateType>;
  serviceProvisionReflectionData: Partial<ServiceProvisionReflectionCreateRetrieveUpdateType>;
  isModify: boolean;
  serviceIsModify: boolean;
}

const initialState: counselingStateType = {
  counselingMonthsData: {},
  counselingDetailData: {},
  serviceProvisionReflectionData: {},
  isModify: false,
  serviceIsModify: false,
};

export const counselingSlice = createSlice({
  name: 'COUNSELING',
  initialState,
  reducers: {
    setCounselingMonthsData: (
      state,
      action: PayloadAction<CounselingCreateRetrieveUpdateType | {}>,
    ) => {
      state.counselingMonthsData = action.payload;
    },
    setCounselingDetailData: (
      state,
      action: PayloadAction<CounselingCreateRetrieveUpdateType | {}>,
    ) => {
      state.counselingDetailData = action.payload;
    },
    setServiceProvisionReflectionData: (
      state,
      action: PayloadAction<
        ServiceProvisionReflectionCreateRetrieveUpdateType | {}
      >,
    ) => {
      state.serviceProvisionReflectionData = action.payload;
    },
    setCounselingIsModify: (state, action: PayloadAction<boolean>) => {
      state.isModify = action.payload;
    },
    setServiceIsModify: (state, action: PayloadAction<boolean>) => {
      state.serviceIsModify = action.payload;
    },
  },
});

export const {
  actions: counselingSliceActions,
  reducer: counselingSliceReducer,
} = counselingSlice;

export default counselingSlice;
