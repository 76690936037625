import Confirm from '../Confirm';
import { ModalProps } from '@chakra-ui/react';

export interface Props extends Omit<ModalProps, 'children'> {
  onCancel: () => void;
  onConfirm: () => void;
}

export default function PageExitConfirmModal({
  isOpen,
  onConfirm,
  onCancel,
  onClose,
}: Props) {
  return (
    <Confirm
      isOpen={isOpen}
      onConfirm={onConfirm}
      onCancel={onCancel}
      onClose={onClose}
      title="작성 중인 내용이 있습니다."
    >
      <>
        화면을 벗어나면 작성된 내용이 모두 삭제됩니다.
        <br />
        변경 내용을 저장하려면 취소 후 저장 버튼을 눌러주세요.
        <br />
        <br />
        정말 이동하시겠습니까?
      </>
    </Confirm>
  );
}
