import * as S from './ErrorView.styled';
import { ReactComponent as Caution } from 'assets/icons/symbol/caution.svg';
import { ReactComponent as FamilycareLogo } from 'assets/images/logo/familycare.svg';

interface Props {
  title: React.ReactNode;
  children: React.ReactNode;
  buttons?: React.ReactNode[];
}

export default function ErrorView({ title, children, buttons }: Props) {
  return (
    <S.ErrorContainer>
      <FamilycareLogo
        width={177}
        fill="#31A575"
      />

      <S.CautionWrapper>
        <Caution width={180} />
      </S.CautionWrapper>

      <S.TextContainer>
        <S.TitleWrapper>
          {title}
        </S.TitleWrapper>
        <S.DescriptionWrapper>
          {children}
        </S.DescriptionWrapper>
      </S.TextContainer>

      {buttons?.length && (
        <S.ButtonContainer>
          {buttons.map((button) => button)}
        </S.ButtonContainer>
      )}
    </S.ErrorContainer>
  );
}
