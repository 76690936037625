import { StyleObjectOrFn } from '@chakra-ui/react';
import { ComponentSingleStyleConfig } from '@chakra-ui/theme';
import { BadgeKDS } from "@theme/components/single/BadgeKDS";

type AccessibleColor = {
  bg?: string;
  color?: string;
  hoverBg?: string;
  activeBg?: string;
  border?: string;
};

const accessibleColorMap: { [key: string]: AccessibleColor } = {
  kakao: {
    bg: 'kakao.500',
    color: '#1A1A1A',
    hoverBg: 'kakao.600',
    activeBg: 'kakao.700',
  },
  naver: {
    bg: 'naver.500',
    color: '#FFFFFF',
    hoverBg: 'naver.600',
    activeBg: 'naver.700',
  },
  facebook: {
    bg: 'facebook.500',
    color: '#FFFFFF',
    hoverBg: 'facebook.600',
    activeBg: 'facebook.700',
  },
  apple: {
    bg: 'apple.500',
    color: '#FFFFFF',
    hoverBg: 'apple.600',
    activeBg: 'apple.700',
  },
  google: {
    bg: 'google.500',
    color: '#808080',
    hoverBg: 'google.600',
    activeBg: 'google.700',
    border: '#DDDDDD',
  },
};

const variantSolid: StyleObjectOrFn = (props) => {
  const { colorScheme: c } = props;

  if (c === 'gray') {
    const bg = 'netural.20';
    return {
      bg,
      color: 'netural.700',
    };
  }

  if (['red', 'blue', 'yellow'].includes(c)) {
    const bg = `${c}.10`;
    return {
      bg,
      color: `${c}.600`,
    };
  }

  if (c === 'netural') {
    const bg = 'netural.20';
    return {
      color: 'netural.500',
      textColor: 'netural.200',
      bg,
    };
  }

  if (c === 'green') {
    const bg = 'green.10';
    return {
      bg,
      color: 'green.500',
    };
  }

  if (c === 'darkGreen') {
    const bg = 'green.500';
    return {
      bg,
      color: 'white',
    };
  }

  const {
    bg = `${c}.500`,
    color = 'white',
    hoverBg = `${c}.600`,
    activeBg = `${c}.700`,
    border = `${c}.500`,
  } = accessibleColorMap[c] ?? {};

  const background = bg;
  const borderColor = border;

  return {
    bg: background,
    color: color,
    borderWidth: 1,
    borderColor: borderColor,
    _hover: {
      bg: hoverBg,
      borderColor: hoverBg,
      _disabled: {
        bg: background,
      },
    },
    _active: { bg: activeBg, borderColor: activeBg },
  };
};

export const Badge: ComponentSingleStyleConfig = {
  baseStyle: {
    w: 'fit-content',
    h: 'fit-content',
    padding: '1px 5px',
    gap: '10px',
    borderRadius: '5px',
    _focus: { boxShadow: 'none' },
  },
  defaultProps: {
    variant: 'solid',
    size: 'xs',
  },
  sizes: {
    xs: {
      fontWeight: '500',
      fontSize: '12px',
      p: '1px 5px',
    },
    ...BadgeKDS.sizes,
  },
  variants: {
    solid: variantSolid,

    ...BadgeKDS.variants,
  },
};
