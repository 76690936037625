import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface ScheduleStateType {
  /** 일정관리 - 수급자 일정관리: 일정확정 플래그
   * -1: 초기값, 0: 해제, 1: 등록
   */
  isScheduleConfirm: number;
}
const initialState: ScheduleStateType = {
  isScheduleConfirm: -1,
};

export const scheduleSlice = createSlice({
  name: 'SCHEDULE',
  initialState,
  reducers: {
    setScheduleConfirm: (state, action: PayloadAction<number>) => {
      state.isScheduleConfirm = action.payload;
    },
  },
});

export const { actions: scheduleSliceActions, reducer: scheduleSliceReducer } =
  scheduleSlice;

export default scheduleSlice;
