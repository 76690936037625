import ErrorView from './ErrorView';
import { Text } from '@chakra-ui/react';

export default function ServiceUnavailableErrorView() {
  return (
    <ErrorView title={(
      <>
        이용에 불편을 드려 죄송합니다.
        <br />
        현재
        {' '}
        <Text as="span" color="#FA4F38">서버 점검 중</Text>
        입니다.
      </>
      )}
    >
      더 나은 서비스 제공을 위해 서버 점검 작업을 진행하고 있어요.
      <br />
      점검 시간 동안 일부 서비스 사용이 불가하니 양해 부탁드립니다.
    </ErrorView>
  );
}
