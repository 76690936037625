import React from 'react';

import * as S from './Confirm.styled';
import {
  Button,
  HStack,
  Modal, ModalProps,
  ModalBody,
  ModalContent, ModalContentProps,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';

export interface ConfirmProps extends Omit<ModalProps, 'children'> {
  title?: React.ReactNode;
  contentProps?: ModalContentProps;
  children?: React.ReactNode;
  onCancel?: () => void;
  onConfirm?: () => void;
  /** extra 버튼 클릭 시 실행 */
  onExtraButtonClick?: () => void;
  /** 취소 버튼 라벨 - 기본값 "취소" */
  cancelLabel?: string;
  /** 확인 버튼 라벨 - 기본값 "확인" */
  confirmLabel?: string;
  /** extra 버튼 라벨 */
  extraButtonLabel?: string;
  /** 확인 버튼 disable */
  confirmDisabled?: boolean;
}
export default function Confirm(props: ConfirmProps) {
  const {
    contentProps = {},
    onCancel,
    onConfirm = () => {},
    onExtraButtonClick,
    title,
    children,
    closeOnEsc = false,
    closeOnOverlayClick = false,
    cancelLabel = '취소',
    confirmLabel = '확인',
    extraButtonLabel = '',
    confirmDisabled = false,
  } = props;

  return (
    <Modal
      {...props}
      isCentered
      closeOnEsc={closeOnEsc}
      closeOnOverlayClick={closeOnOverlayClick}
    >
      <ModalOverlay />
      <ModalContent
        {...S.modalContent}
        {...contentProps}
      >
        <ModalHeader {...S.modalHeader}>{title}</ModalHeader>
        <ModalBody {...S.modalBody}>{children}</ModalBody>
        <ModalFooter {...S.modalFooter}>
          <HStack spacing="12px">
            {onCancel && (
              <Button p="12px 20px" size="large" variant="gray" onClick={onCancel}>
                {cancelLabel}
              </Button>
            )}
            {onExtraButtonClick && (
              <Button p="12px 20px" size="large" color="var(--G700)" variant="outlineGreen" outline="none" onClick={onExtraButtonClick}>
                {extraButtonLabel}
              </Button>
            )}
            <Button p="12px 20px" size="large" variant="green" disabled={confirmDisabled} onClick={onConfirm}>
              {confirmLabel}
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
