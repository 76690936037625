import ErrorView from './ErrorView';
import { ErrorViewProps } from './types';
import { Text } from '@chakra-ui/react';
import Button from '@components/v2/Button';
import useChannelTalk from 'hooks/v2/useChannelTalk';
import useRouterPush from 'hooks/v2/useRouterPush';

export default function NotFoundErrorView({ onResetError }: ErrorViewProps) {
  const { routerPush } = useRouterPush();
  const { handleShowMessenger } = useChannelTalk();

  return (
    <ErrorView
      title="원하시는 페이지를 찾을 수가 없습니다."
      buttons={[
        <Button
          key="home-button"
          type="button"
          size="xlarge"
          width="209px"
          variant="green"
          onClick={() => {
            onResetError();
            routerPush({
              pathname: '/',
              query: { tab: undefined },
            });
          }}
        >
          패밀리케어 홈으로 이동
        </Button>,
      ]}
    >
      지속적으로 오류가 발생한다면
      <br />
      <Text
        as="span"
        color="#208DF9"
        cursor="pointer"
        onClick={handleShowMessenger}
      >
        고객센터
      </Text>
      를 통해 내용을 전달해 주세요.
    </ErrorView>
  );
}
