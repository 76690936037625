import { ChakraMultiPartComponentType } from '../type';

const parts = ['control', 'icon', 'container', 'label'] as const;

export const Checkbox: ChakraMultiPartComponentType<typeof parts> = {
  parts,
  baseStyle: {
    control: {
      outline: 'none',
      boxShadow: 'none',
      _focus: {
        boxShadow: 'none',
      },
    },
  },
  defaultProps: {
    variant: 'solid',
    colorScheme: 'green',
  },
  sizes: {},
  variants: {
    solid: (props) => {
      const { colorScheme: c } = props;
      if (c === 'green') {
        return {
          control: {
            _checked: {
              bg: 'green.600',
              border: 'none',
            },
          },
        };
      }

      if (c === 'disabled') {
        return {
          control: {
            _checked: {
              bg: 'green.100',
              border: 'none',
              color: 'green.300',
            },
          },
        };
      }

      return {
        control: {
          _checked: {
            bg: 'blue.500',
          },
        },
      };
    },
  },
};
