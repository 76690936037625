import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import {
  EmployeeMinutesDetailType,
  EmployeeReadType as EmployeeType,
} from 'generated/apis/@types/data-contracts';

export interface EmployeeStateType {
  employeeData: Partial<EmployeeType>;
  meetingStatus: 'create' | 'read' | 'modify';
  previousData: boolean;
  employeeMinutesId: number | undefined;
  employeeEducationId: number | undefined;
  employeeEducationListId: number | undefined;
  employeeWelfareId: number | undefined;
  employeeHealthCheckupId: number | undefined;
  caseMinutesId: number | undefined;
  employeeMinutesDetailData: EmployeeMinutesDetailType | {};
}

const initialState: EmployeeStateType = {
  employeeData: {},
  previousData: false,
  meetingStatus: 'create',
  employeeMinutesId: undefined,
  employeeEducationId: undefined,
  employeeEducationListId: undefined,
  employeeWelfareId: undefined,
  employeeHealthCheckupId: undefined,
  caseMinutesId: undefined,
  employeeMinutesDetailData: {},
};

export const employeeSlice = createSlice({
  name: 'EMPLOYEE',
  initialState,
  reducers: {
    setEmployeeData: (state, action: PayloadAction<Partial<EmployeeType>>) => {
      state.employeeData = action.payload;
    },
    setPreviousData: (state, action: PayloadAction<boolean>) => {
      state.previousData = action.payload;
    },
    setMettingStatus: (
      state,
      action: PayloadAction<'create' | 'read' | 'modify'>,
    ) => {
      state.meetingStatus = action.payload;
    },
    setEmployeeMinutesId: (
      state,
      action: PayloadAction<number | undefined>,
    ) => {
      state.employeeMinutesId = action.payload;
    },
    setEmployeeEducationId: (
      state,
      action: PayloadAction<number | undefined>,
    ) => {
      state.employeeEducationId = action.payload;
    },
    setEmployeeEducationListId: (
      state,
      action: PayloadAction<number | undefined>,
    ) => {
      state.employeeEducationListId = action.payload;
    },
    setEmployeeWelfareId: (
      state,
      action: PayloadAction<number | undefined>,
    ) => {
      state.employeeWelfareId = action.payload;
    },
    setEmployeeHealthCheckupId: (
      state,
      action: PayloadAction<number | undefined>,
    ) => {
      state.employeeHealthCheckupId = action.payload;
    },
    setCaseMinutesId: (state, action: PayloadAction<number | undefined>) => {
      state.caseMinutesId = action.payload;
    },
    setEmployeeMinutesDetailData: (
      state,
      action: PayloadAction<EmployeeMinutesDetailType>,
    ) => {
      state.employeeMinutesDetailData = action.payload;
    },
  },
});

export const { actions: employeeSliceActions, reducer: employeeSliceReducer } =
  employeeSlice;

export default employeeSlice;
