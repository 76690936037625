import { accordionAnatomy as parts } from '@chakra-ui/anatomy';
import {
  createMultiStyleConfigHelpers,
  defineStyle,
} from '@chakra-ui/styled-system';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

const baseStyleContainer = defineStyle({
  borderTopWidth: '0px',

  borderColor: 'netural.40',
  borderWidth: '1px',
  borderBottomWidth: '0px',

  _first: {
    borderTopRadius: '8px',
    overflow: 'hidden',
  },
  _last: {
    borderBottomWidth: '1px',
    borderBottomRadius: '8px',
    overflow: 'hidden',
  },
  _expanded: {
    borderColor: 'green.100',
  },
});

const baseStyleButton = defineStyle({
  h: '48px',
  bg: 'none',

  _focusVisible: {
    boxShadow: 'none',
  },
  _focus: {
    boxShadow: 'none',
  },
  _hover: {
    bg: 'none',
  },
  _disabled: {
    opacity: 0.4,
    cursor: 'not-allowed',
  },
  _expanded: {
    bg: 'none',
    borderBottomWidth: 'none',
    borderColor: 'none',
    pt: '1px',
  },
  px: '30px',
  py: '0',
});

const baseStylePanel = defineStyle({
  // borderRightWidth: '1px',
  // borderLeftWidth: '1px',
  pt: '2',
  px: '4',
  pb: '5',
});

const baseStyleIcon = defineStyle({
  fontSize: '1.25em',
});

const baseStyle = definePartsStyle({
  container: baseStyleContainer,
  button: baseStyleButton,
  panel: baseStylePanel,
  icon: baseStyleIcon,
});

export const Accordion = defineMultiStyleConfig({ baseStyle });
