import { tableAnatomy as parts } from '@chakra-ui/anatomy';
import {
  createMultiStyleConfigHelpers,
  defineStyle,
} from '@chakra-ui/styled-system';
import { mode } from '@chakra-ui/theme-tools';
import { TableKDS } from "@theme/components/multi-parts/TableKDS";

const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(parts.keys);

const sizes = {
  sm: definePartsStyle({
    th: {
      px: '10px',
      py: '1',
      lineHeight: '4',
      fontSize: '13px',
      textAlign: 'center',
    },
    td: {
      px: '10px',
      py: '2',
      fontSize: '13px',
      lineHeight: '4',
    },
    caption: {
      px: '10px',
      py: '2',
      fontSize: '13px',
    },
  }),
  md: definePartsStyle({
    th: {
      px: '10px',
      py: '1',
      lineHeight: '4',
      fontSize: '13px',
    },
    td: {
      px: '10px',
      py: '2',
      fontSize: '13px',
      lineHeight: '4',
    },
    caption: {
      px: '10px',
      py: '2',
      fontSize: '13px',
    },
  }),
  lg: definePartsStyle({
    th: {
      px: '10px',
      py: '1',
      lineHeight: '4',
      fontSize: '13px',
    },
    td: {
      px: '10px',
      py: '2',
      fontSize: '13px',
      lineHeight: '4',
    },
    caption: {
      px: '10px',
      py: '2',
      fontSize: '13px',
    },
  }),
};

const baseStyle = definePartsStyle({
  table: {
    tableLayout: 'fixed',
  },
  td: {
    color: 'netural.500',
    textAlign: 'center',
    h: '40px',
    px: 0,
    overflow: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  th: {
    bg: 'netural.10',
    textAlign: 'center',
    px: 0,
    textTransform: 'unset',
    whiteSpace: 'normal',
    wordBreak: 'keep-all',
    p: {
      textStyle: 'caption1_medium',
      color: 'netural.700',
    },
  },
  tr: {
    h: '40px',
    borderBottomWidth: '1px',
    borderBottomColor: 'netural.40',
    _first: {
      borderTopWidth: 0,
    },
    _last: {
      borderBottomWidth: 0,
    },
  },
  thead: {
    position: 'sticky',
    top: 0,
    borderTopWidth: '1px',
    borderBottomWidth: '1px',
    borderColor: 'netural.40',
    zIndex: 2,
  },
  tbody: {
    zIndex: 1,
  },
  tfoot: {
    position: 'sticky',
    bottom: '0',
    bg: 'white',
    boxShadow: '0px -4px 10px rgba(0, 0, 0, 0.05)',
  },
});

const numericStyles = defineStyle({
  '&[data-is-numeric=true]': {
    textAlign: 'end',
  },
});

const variantSimple = definePartsStyle((props) => {
  const { colorScheme: c } = props;

  return {
    th: {
      color: mode('gray.600', 'gray.400')(props),
      borderBottom: '1px',
      borderColor: 'netural.40',
      ...numericStyles,
    },
    td: {
      borderBottom: '1px',
      borderColor: 'netural.40',
      ...numericStyles,
    },
    caption: {
      color: mode('gray.600', 'gray.100')(props),
    },
    tfoot: {
      tr: {
        '&:last-of-type': {
          th: { borderBottomWidth: 0 },
        },
      },
    },
  };
});

const variantAccordion = definePartsStyle((props) => {
  const { colorScheme: c } = props;

  return {
    table: {
      borderBottomWidth: '1px',
    },
    tr: {
      '&:last-of-type th': {
        borderBottom: '0px',
      },
      '&:last-of-type td': {
        borderBottom: '0px',
      },
    },
    th: {
      color: mode('gray.600', 'gray.400')(props),
      borderRightWidth: '1px',
      ...numericStyles,
      '&:last-of-type': {
        borderRight: '0px',
      },
    },
    td: {
      borderBottom: '1px',
      borderRight: '1px',
      borderColor: 'netural.40',
      ...numericStyles,
      '&:last-of-type': {
        borderRight: '0px',
      },
    },
    caption: {
      color: mode('gray.600', 'gray.100')(props),
    },
    tfoot: {
      tr: {
        '&:last-of-type': {
          th: { borderBottomWidth: 0 },
        },
      },
    },
  };
});

const variantRound = definePartsStyle((props) => {
  return {
    table: {
      borderWidth: '1px',
      borderRadius: '8px',
      overflow: 'hidden',
      borderCollapse: 'separate',
      borderSpacing: 0,
    },
    thead: {
      borderTopWidth: 0,
      borderColor: 'netural.40',
    },
    tr: {
      '&:last-of-type th': {
        borderBottomWidth: '0px',
      },
      '&:first-of-type td': {
        borderTopWidth: '1px',
      },
      '&:last-of-type td': {
        borderBottomWidth: '0px',
      },
    },
    th: {
      w: '100%',
      color: mode('gray.600', 'gray.400')(props),
      borderBottomWidth: '1px',
      borderRightWidth: '1px',
      ...numericStyles,
      '&:last-of-type': {
        borderRightWidth: '0px',
      },
    },
    td: {
      borderBottomWidth: '1px',
      borderRightWidth: '1px',
      borderColor: 'netural.40',
      ...numericStyles,
      '&:last-of-type': {
        borderRightWidth: '0px',
      },
      textStyle: 'caption1_regular',
    },
    caption: {
      color: mode('gray.600', 'gray.100')(props),
    },
    tfoot: {
      tr: {
        '&:last-of-type': {
          th: { borderBottomWidth: 0 },
        },
      },
    },
  };
});

const variantRound2 = definePartsStyle((props) => {
  return {
    table: {
      borderWidth: '1px',
      borderRadius: '8px',
      overflow: 'hidden',
      display: 'inline-block',
      tableLayout: 'fixed',
    },
    thead: {
      borderTopWidth: 0,
      borderColor: 'netural.40',
    },
    tr: {
      '&:last-of-type th': {
        borderBottomWidth: '0px',
      },
      '&:last-of-type td': {
        borderBottomWidth: '1px',
      },
    },
    th: {
      color: 'netural.700',
      fontWeight: '500',
      borderWidth: '0 1px 1px 0',
      borderColor: 'netural.40',
      ...numericStyles,
      '&:last-of-type': {
        borderRightWidth: '0px',
      },
    },
    td: {
      borderWidth: '0 1px 1px 0',
      borderColor: 'netural.40',
      ...numericStyles,
      '&:last-of-type': {
        borderRightWidth: '0px',
      },
      textStyle: 'caption1_regular',
      textColor: 'netural.500',
      px: '15px',
    },
    caption: {
      color: mode('gray.600', 'gray.100')(props),
    },
    tfoot: {
      tr: {
        '&:last-of-type': {
          th: { borderBottomWidth: 0 },
        },
      },
    },
  };
});

const variantStripe = definePartsStyle((props) => {
  const { colorScheme: c } = props;

  return {
    th: {
      color: mode('gray.600', 'gray.400')(props),
      borderBottom: '1px',
      borderColor: 'netural.40',
      ...numericStyles,
    },
    td: {
      borderBottom: '1px',
      borderColor: 'netural.40',
      ...numericStyles,
    },
    caption: {
      color: mode('gray.600', 'gray.100')(props),
    },
    tbody: {
      tr: {
        '&:nth-of-type(odd)': {
          'th, td': {
            borderBottomWidth: '1px',
            borderColor: 'netural.40',
          },
          td: {
            background: mode(`${c}.100`, `${c}.700`)(props),
          },
        },
      },
    },
    tfoot: {
      tr: {
        '&:last-of-type': {
          th: { borderBottomWidth: 0 },
        },
      },
    },
  };
});

const variantDatePicker = definePartsStyle((props) => {
  const { colorScheme: c } = props;

  return {
    table: {},
    th: { bg: 'white', color: 'netural.700' },
    tr: {
      border: 'none',
    },
    caption: {
      color: mode('gray.600', 'gray.100')(props),
    },
    tbody: {
      tr: {
        '&:last-of-type': {
          borderBottom: '1px solid #ffffff',
        },
      },
    },
  };
});

const variantBase = definePartsStyle((props) => {
  const { colorScheme: c } = props;

  return {
    table: {
      tableLayout: 'fixed',
    },
    thead: {
      borderTopWidth: 0,
      borderColor: 'netural.40',
    },
    tfoot: {
      tr: {
        _hover: {
          bg: 'transparent',
        },
      },
    },
    th: {
      color: mode('gray.600', 'gray.400')(props),
      borderRightWidth: '1px',
      p: { textStyle: 'caption1_medium', textColor: 'netural.700' },
      ...numericStyles,
    },
    td: {
      borderRight: '1px',
      borderColor: 'netural.40',
      ...numericStyles,
    },
    tr: {
      '&:last-of-type td': {
        borderBottomWidth: '1px',
      },
      _hover: {
        backgroundColor: 'netural.10',
      },
    },
  };
});

const variants = {
  simple: variantSimple,
  striped: variantStripe,
  round: variantRound,
  roundborder: variantRound2,
  accordion: variantAccordion,
  unstyled: defineStyle({
    tr: {
      '&:last-of-type td': {
        borderBottomWidth: '1px',
      },
    },
  }),
  datepicker: variantDatePicker,
  base: variantBase,
  ...TableKDS.variants,
};

export const Table = defineMultiStyleConfig({
  baseStyle,
  variants,
  sizes,
  defaultProps: {
    variant: 'striped',
    size: 'md',
    colorScheme: 'gray',
  },
});
