/* eslint-disable @typescript-eslint/no-empty-function */
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useState,
} from 'react';

import PageExitConfirmModal from '@components/v2/PageExitConfirmModal';

interface Props {
  children: ReactNode;
}

export interface PageExitConfirmContext {
  onOpenModal: Dispatch<SetStateAction<boolean>>;
  onCloseModal: Dispatch<SetStateAction<boolean>>;
  isClickedConfirmButton: boolean;
  setIsClickedConfirmButton: Dispatch<SetStateAction<boolean>>;
}

const defaultConfirmContext = {
  onOpenModal: () => {},
  onCloseModal: () => {},
  isClickedConfirmButton: false,
  setIsClickedConfirmButton: () => {},
};

export const PageExitConfirmContext = createContext<PageExitConfirmContext>(
  defaultConfirmContext,
);

const PageExitConfirmProvider = ({ children }: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isClickedConfirmButton, setIsClickedConfirmButton] =
    useState<boolean>(false);

  const confirmValue = {
    onOpenModal: () => setIsOpen(true),
    onCloseModal: () => {
      setIsClickedConfirmButton(true);
      setIsOpen(false);
    },
    isClickedConfirmButton,
    setIsClickedConfirmButton,
  };

  const handleConfirmButton = () => {
    setIsClickedConfirmButton(true); // 외부에서 확인 버튼이 클릭 됐는지 체크하기 위한 용도
    setIsOpen(false);
  };

  const handleCloseButton = () => {
    setIsOpen(false);
  };

  return (
    <PageExitConfirmContext.Provider value={confirmValue}>
      <PageExitConfirmModal
        isOpen={isOpen}
        onConfirm={handleConfirmButton}
        onCancel={handleCloseButton}
        onClose={handleCloseButton}
      />
      {children}
    </PageExitConfirmContext.Provider>
  );
};

export default PageExitConfirmProvider;
