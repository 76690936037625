import { create } from 'zustand';

export interface ChannelTalkStatus {
  /**
   * - `idle` - 초기 상태
   * - `booted` - Boot 완료
   * - `error` - Boot 오류
   * - `logout` - 로그인 되어 있지 않음
   */
  status: 'idle' | 'booted' | 'error' | 'logout';
  error: Error | null;
}

export interface UseChannelTalkStatus extends ChannelTalkStatus {
  setChannelTalkStatus: (channelTalkStatus: ChannelTalkStatus) => void;
}

export const useChannelTalkStatus = create<UseChannelTalkStatus>((set) => ({
  status: 'idle',
  error: null,
  setChannelTalkStatus: (channelTalkStatus: ChannelTalkStatus) => set(() => channelTalkStatus),
}));
