/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect } from 'react';

import * as ChannelService from '@channel.io/channel-web-sdk-loader';
import type { BootOption } from '@channel.io/channel-web-sdk-loader';
import * as Sentry from '@sentry/nextjs';

import useChannelTalkMember from 'hooks/v2/queries/useChannelTalkMember';
import useUser from 'hooks/v2/queries/useUser';
import { useChannelTalkStatus } from 'lib/state/store';
import useRouterPush from 'hooks/v2/useRouterPush';

export default function ChannelTalkBoot() {
  const setChannelTalkStatus = useChannelTalkStatus((store) => store.setChannelTalkStatus);

  const { router } = useRouterPush();

  const { data: user, isFetched: isFetchedUser } = useUser({
    retry: false,
    refetchInterval: false,
    refetchOnMount: false,
    enabled: !router.pathname.includes('notice_talk'),
  });

  const { data, isFetched } = useChannelTalkMember({ enabled: !!user?.id });

  useEffect(() => {
    if (!user?.id || (isFetched && !data?.service_key)) {
      setChannelTalkStatus({ status: 'logout', error: null });
      return;
    }

    if (isFetchedUser && isFetched && data?.service_key) {
      ChannelService.loadScript();

      ChannelService.shutdown();

      // https://developers.channel.io/reference/web-boot-option-kr
      const bootOptions: BootOption = {
        pluginKey: data?.service_key,
        hidePopup: true,
        hideChannelButtonOnBoot: true,
        language: 'ko',

        // 유저 정보
        memberId: data?.member_id,
        memberHash: data?.member_hash,

        // 유저 프로필
        profile: {
          name: user?.name ?? null,
          usertype: user?.type ?? null,
          apptype: 'fn',
        },
      };

      ChannelService.boot(
        bootOptions,
        (err) => {
          if (err) {
            setChannelTalkStatus({ status: 'error', error: err });

            // err.type이 undefined로 들어오는 경우는 대부분 네트워크 오류로 수집하지 않는다.
            if (err && 'type' in err) {
              // @ts-ignore
              const error = new Error(`ChannelTalk Boot Error (${err.type})`);

              Sentry.captureException(error, {
                contexts: {
                  channelTalk: {
                    bootOptions,
                    error: err,
                  },
                },
                // @ts-ignore
                fingerprint: ['channel-talk', `${err.type}`],
              });
            }
          } else {
            setChannelTalkStatus({ status: 'booted', error: null });
          }
        },
      );
    }
  }, [
    isFetchedUser,
    user?.id,
    user?.name,
    user?.type,
    isFetched,
    data?.service_key,
    data?.member_id,
    data?.member_hash,
    setChannelTalkStatus,
  ]);

  return <></>;
}
