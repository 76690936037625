import React, { forwardRef } from 'react';

import { Button as ChakraButton, ButtonProps as ChakraButtonProps } from '@chakra-ui/react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

const AFTER = css`
  :after {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const ChakraButtonStyled = styled(ChakraButton)`
  & > span {
    margin-inline: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
  }
  :hover {
    ${AFTER}
  }
  :active {
    ${AFTER}
  }
  :disabled {
    pointer-events: none;
    ${AFTER}
  }
`;

/**
 * 테마 파일을 참조해 주세요.
 * src/styles/theme/components/single/ButtonKDS.ts
 */
interface ButtonProps extends ChakraButtonProps {
  variants?:
  'gray'
  | 'lightGray'
  | 'secondaryGray'
  | 'white'
  | 'red'
  | 'green'
  | 'kakao'
  | 'naver'
  | 'facebook'
  | 'apple'
  | 'google'
  | 'light'

  | 'primary'
  | 'primaryBlue'
  | 'secondary'
  | 'secondaryBlue'
  | 'default'
  | 'outline'
  | 'subtle'
  | 'danger'
  | 'dangerSolid';
  sizes?: 'xxsmall' | 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge' | 'xxlarge';
}

function Button({
  variants = 'default',
  sizes = 'medium',
  ...props
}: ButtonProps, ref: React.ForwardedRef<HTMLButtonElement>) {
  return (
    <ChakraButtonStyled
      ref={ref}
      variants={variants}
      size={sizes}
      {...props}
    >
      {props.children}
    </ChakraButtonStyled>
  );
}

export default forwardRef<HTMLButtonElement, ButtonProps>(Button);
