import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { RecipientType } from 'generated/apis/@types/data-contracts';

export interface RecipientStateType {
  recipientData: Partial<RecipientType>;
  recipientListData: RecipientType[];
  recipientInfoStatus: 'create' | 'modify';
}

const initialState: RecipientStateType = {
  recipientData: {},
  recipientListData: [],
  recipientInfoStatus: 'create',
};

export const recipientSlice = createSlice({
  name: 'RECIPIENT',
  initialState,
  reducers: {
    setRecipientData: (
      state,
      action: PayloadAction<Partial<RecipientType>>,
    ) => {
      state.recipientData = action.payload;
    },
    setRecipientListData: (state, action: PayloadAction<RecipientType[]>) => {
      state.recipientListData = action.payload;
    },
    setRecipientInfoStatus: (
      state,
      action: PayloadAction<'create' | 'modify'>,
    ) => {
      state.recipientInfoStatus = action.payload;
    },
  },
});

export const {
  actions: recipientSliceActions,
  reducer: recipientSliceReducer,
} = recipientSlice;

export default recipientSlice;
