import ForbiddenErrorView from './ForbiddenErrorView';
import InternalServerErrorView from './InternalServerErrorView';
import NotFoundErrorView from './NotFoundErrorView';
import ServiceUnavailableErrorView from './ServiceUnavailableErrorView';
import { ErrorViewProps } from './types';
import ForbiddenError from 'lib/errors/ForbiddenError';
import NotFoundError from 'lib/errors/NotFoundError';
import ServiceUnavailableError from 'lib/errors/ServiceUnavailableError';
import UnauthorizedError from 'lib/errors/UnauthorizedError';

interface Props {
  error: Error;
  resetError: ErrorViewProps['onResetError'];
}

export default function SentryErrorFallbackView({ error, resetError }: Props) {
  if (error instanceof UnauthorizedError) {
    window.location.replace(process.env.NEXT_PUBLIC_LOGIN_REDIRECT_URL ?? '');
    return <></>;
  }

  if (error instanceof ForbiddenError) {
    return <ForbiddenErrorView />;
  }

  if (error instanceof NotFoundError) {
    return <NotFoundErrorView onResetError={resetError} />;
  }

  if (error instanceof ServiceUnavailableError) {
    return <ServiceUnavailableErrorView />;
  }

  return <InternalServerErrorView onResetError={resetError} />;
}
