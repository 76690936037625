import { AxiosResponse, AxiosError } from 'axios';
import { UseQueryOptions, QueryKey, useQuery } from 'react-query';

import client from '@apis/_axios/client';
import { components } from 'types/generated/dc';

export const QUERY_KEY = '/users/chatbot/';

export default function useChannelTalkMember(options?: UseQueryOptions<AxiosResponse, AxiosError, components['schemas']['Chatbot']>) {
  return useQuery(
    [QUERY_KEY] as QueryKey,
    () => client.get(QUERY_KEY),
    {
      retry: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      select: (response) => response.data,
      ...options,
    },
  );
}
