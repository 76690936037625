import { NextRouter, useRouter } from 'next/router';
import { UrlObject } from 'url';

// node_modules/next/dist/shared/lib/router/router.d.ts
export type Url = UrlObject | string;

export interface TransitionOptions {
  shallow?: boolean;
  locale?: string | false;
  scroll?: boolean;
}

export interface RouterPushProps {
  pathname: string;
  routerQuery?: Record<string, any>;
  query?: Record<string, any>;
  urlOptions?: UrlObject;
  as?: Url;
  options?: TransitionOptions
}

export const useRouterPush = ()=> {
  const router = useRouter();

  return {
    router,
    routerPush: ({ pathname, query, urlOptions, as, options }: RouterPushProps) => {
      return router.push(
        {
          ...urlOptions,
          pathname,
          query: {
            tab: router.query.tab,
            center_id: router.query.center_id,
            ...query,
          },
        },
        as,
        options,
      );
    },
  };
}

export default useRouterPush;
