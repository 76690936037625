const textStyles = {
  display1_regular: {
    fontSize: '64px',
    lineHeight: '1.2',
    fontWeight: 400,
    letterSpacing: '-0.02em',
  },
  display1_medium: {
    fontSize: '64px',
    lineHeight: '1.2',
    fontWeight: 500,
    letterSpacing: '-0.02em',
  },
  display1_bold: {
    fontSize: '64px',
    lineHeight: '1.2',
    fontWeight: 700,
    letterSpacing: '-0.015em',
  },
  display2_regular: {
    fontSize: '56px',
    lineHeight: '1.2',
    fontWeight: 400,
    letterSpacing: '-0.02em',
  },
  display2_medium: {
    fontSize: '56px',
    lineHeight: '1.2',
    fontWeight: 500,
    letterSpacing: '-0.02em',
  },
  display2_bold: {
    fontSize: '56px',
    lineHeight: '1.2',
    fontWeight: 700,
    letterSpacing: '-0.015em',
  },
  display3_regular: {
    fontSize: '48px',
    lineHeight: '1.2',
    fontWeight: 400,
    letterSpacing: '-0.02em',
  },
  display3_medium: {
    fontSize: '48px',
    lineHeight: '1.2',
    fontWeight: 500,
    letterSpacing: '-0.02em',
  },
  display3_bold: {
    fontSize: '48px',
    lineHeight: '1.2',
    fontWeight: 700,
    letterSpacing: '-0.015em',
  },
  display4_regular: {
    fontSize: '40px',
    lineHeight: '1.2',
    fontWeight: 400,
    letterSpacing: '-0.02em',
  },
  display4_medium: {
    fontSize: '40px',
    lineHeight: '1.2',
    fontWeight: 500,
    letterSpacing: '-0.02em',
  },
  display4_bold: {
    fontSize: '40px',
    lineHeight: '1.2',
    fontWeight: 700,
    letterSpacing: '-0.015em',
  },
  heading1_regular: {
    fontSize: '32px',
    lineHeight: '1.2',
    fontWeight: 400,
    letterSpacing: '-0.02em',
  },
  heading1_medium: {
    fontSize: '32px',
    lineHeight: '1.2',
    fontWeight: 500,
    letterSpacing: '-0.02em',
  },
  heading1_bold: {
    fontSize: '32px',
    lineHeight: '1.2',
    fontWeight: 700,
    letterSpacing: '-0.015em',
  },
  heading2_regular: {
    fontSize: '24px',
    lineHeight: '1.2',
    fontWeight: 400,
    letterSpacing: '-0.02em',
  },
  heading2_medium: {
    fontSize: '24px',
    lineHeight: '1.2',
    fontWeight: 500,
    letterSpacing: '-0.02em',
  },
  heading2_bold: {
    fontSize: '24px',
    lineHeight: '1.2',
    fontWeight: 700,
    letterSpacing: '-0.015em',
  },
  heading3_regular: {
    fontSize: '21px',
    lineHeight: '1.2',
    fontWeight: 400,
    letterSpacing: '-0.02em',
  },
  heading3_medium: {
    fontSize: '21px',
    lineHeight: '1.2',
    fontWeight: 500,
    letterSpacing: '-0.02em',
  },
  heading3_bold: {
    fontSize: '21px',
    lineHeight: '1.2',
    fontWeight: 700,
    letterSpacing: '-0.015em',
  },
  heading4_regular: {
    fontSize: '19px',
    lineHeight: '1.2',
    fontWeight: 400,
    letterSpacing: '-0.02em',
  },
  heading4_medium: {
    fontSize: '19px',
    lineHeight: '1.2',
    fontWeight: 500,
    letterSpacing: '-0.02em',
  },
  heading4_bold: {
    fontSize: '19px',
    lineHeight: '1.2',
    fontWeight: 700,
    letterSpacing: '-0.015em',
  },
  body1_regular: {
    fontSize: '17px',
    lineHeight: '1.5',
    fontWeight: 400,
    letterSpacing: '-0.02em',
  },
  body1_medium: {
    fontSize: '17px',
    lineHeight: '1.5',
    fontWeight: 500,
    letterSpacing: '-0.02em',
  },
  body1_bold: {
    fontSize: '17px',
    lineHeight: '1.5',
    fontWeight: 700,
    letterSpacing: '-0.02em',
  },
  body2_regular: {
    fontSize: '16px',
    lineHeight: '1.5',
    fontWeight: 400,
    letterSpacing: '-0.02em',
  },
  body2_medium: {
    fontSize: '16px',
    lineHeight: '1.5',
    fontWeight: 500,
    letterSpacing: '-0.02em',
  },
  body2_bold: {
    fontSize: '16px',
    lineHeight: '1.5',
    fontWeight: 700,
    letterSpacing: '-0.02em',
  },
  body3_regular: {
    fontSize: '15px',
    lineHeight: '1.5',
    fontWeight: 400,
    letterSpacing: '-0.02em',
  },
  body3_medium: {
    fontSize: '15px',
    lineHeight: '1.5',
    fontWeight: 500,
    letterSpacing: '-0.02em',
  },
  body3_bold: {
    fontSize: '15px',
    lineHeight: '1.5',
    fontWeight: 700,
    letterSpacing: '-0.02em',
  },
  body4_regular: {
    fontSize: '14px',
    lineHeight: '1.5',
    fontWeight: 400,
    letterSpacing: '-0.02em',
  },
  body4_medium: {
    fontSize: '14px',
    lineHeight: '1.5',
    fontWeight: 500,
    letterSpacing: '-0.02em',
  },
  body4_bold: {
    fontSize: '14px',
    lineHeight: '1.5',
    fontWeight: 700,
    letterSpacing: '-0.02em',
  },
  caption1_regular: {
    fontSize: '13px',
    lineHeight: '1.5',
    fontWeight: 400,
    letterSpacing: '-0.02em',
  },
  caption1_medium: {
    fontSize: '13px',
    lineHeight: '1.5',
    fontWeight: 500,
    letterSpacing: '-0.015em',
  },
  caption1_bold: {
    fontSize: '13px',
    lineHeight: '1.5',
    fontWeight: 700,
    letterSpacing: '-0.015em',
  },
  caption2_regular: {
    fontSize: '12px',
    lineHeight: '1.5',
    fontWeight: 400,
    letterSpacing: '-0.02em',
  },
  caption2_medium: {
    fontSize: '12px',
    lineHeight: '1.5',
    fontWeight: 500,
    letterSpacing: '-0.015em',
  },
  caption2_bold: {
    fontSize: '12px',
    lineHeight: '1.5',
    fontWeight: 700,
    letterSpacing: '-0.01em',
  },
  overline_regular: {
    fontSize: '11px',
    lineHeight: '1.2',
    fontWeight: 400,
    letterSpacing: '-0.02em',
  },
  overline_medium: {
    fontSize: '11px',
    lineHeight: '1.2',
    fontWeight: 500,
    letterSpacing: '-0.01em',
  },
  overline_bold: {
    fontSize: '11px',
    lineHeight: '1.2',
    fontWeight: 700,
    letterSpacing: '-0.01em',
  },
  text_sm: {
    fontSize: '11px',
    lineHeight: '14px',
    fontWeight: 500,
    letterSpacing: '-0.02em',
  },
};

export default textStyles;
