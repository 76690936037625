import { getColorSchema } from '@utils/color-generator';

const neutral = {
  10: '#F9FAFB',
  20: '#F1F3F5',
  30: '#E9ECEF',
  40: '#DEE1E6',
  50: '#C2C8CF',
  60: '#B1B8C0',
  90: '#8C95A1',
  200: '#6C7682',
  500: '#4C5561',
  700: '#333C47',
  900: '#1B2027',
};

const Colors = {
  primary: {
    400: getColorSchema('#4ECA97'),
  },

  blue: {
    10: '#F0F7FF',
    50: '#E6F3FE',
    100: '#CDE5FE',
    200: '#AAD4FD',
    300: '#79BAFC',
    400: '#57A9FB',
    500: 'linear-gradient(0deg, #429EFA, #429EFA), #FFFFFF',
    600: '#208DF9',
    700: '#0679EF',
    800: '#0569CC',
    900: '#055DB3',
  },
  red: {
    10: '#FFF3F2',
    50: '#FEE9E6',
    100: '#FFE3E3',
    200: '#FDB4AA',
    300: '#FC8979',
    400: '#FB6A57',
    500: '#FA4F38',
    600: '#F93015',
    700: '#E01F06',
    800: '#C71C05',
    900: '#B31905',
  },
  yellow: {
    10: '#FFFBE8',
    50: '#FFF8D6',
    100: '#FFF2B2',
    200: '#FFEB84',
    300: '#FFE047',
    400: '#FAD500',
    500: '#FFC400',
    600: '#FFAB00',
    700: '#FF991F',
    800: '#F08C00',
    900: '#E67B00',
  },
  green: {
    10: '#F2FAF7',
    50: '#E4F6EF',
    100: '#C9EDDF',
    200: '#A9E5CC',
    300: '#82D9B5',
    400: '#4ECA97',
    500: '#37B983',
    600: '#31A575',
    700: '#2A8D64',
    800: '#267E59',
    900: '#227251',
  },
  netural: neutral,
  neutral,
  tl: {
    '000': '#1B2027',
    '004': '#242930',
    '006': '#292D34',
    '015': '#3D4147',
    '030': '#5F6368',
    '054': '#96989C',
    '068': '#B6B8BA',
    '084': '#DBDBDC',
  },
  black: '#000000',
  white: '#FFFFFF',
  hover: '#E9EAEC',
  background: '#FCFDFD',
};

const Light = {
  T: {
    '084': '#000000D6',
    '068': '#000000AD',
    '054': '#0000008A',
    '030': '#0000004D',
    '015': '#00000026',
    '006': '#0000000F',
    '004': '#0000000A',
    '000': '#00000000',
  },
};
const Dark = {
  T: {
    '084': '#FFFFFFD6',
    '068': '#FFFFFFAD',
    '054': '#FFFFFF8A',
    '030': '#FFFFFF4D',
    '015': '#FFFFFF26',
    '006': '#FFFFFF0F',
    '004': '#FFFFFF0A',
    '000': '#FFFFFF00',
  },
};

const Brand = {
  kakao: {
    500: '#FFDE32',
    600: '#F5D322',
    700: '#EFCC1A',
  },
  naver: {
    500: '#20CF5D',
    600: '#17c554',
    700: '#14bf50',
  },
  facebook: {
    500: '#1877F3',
    600: '#1874eb',
    700: '#146cdf',
  },
  google: {
    500: '#FFFFFF',
    600: '#F5F5F5',
    700: '#EEEEEE',
  },
  apple: {
    500: '#000000',
    600: '#111111',
    700: '#222222',
  },
};

const Dim = {
  primary: '#1A1A1A80',
  secondary: '#1A1A1A33',
};

const mode = {
  light: {
    ...Colors,
  },
  dark: {
    ...Colors,
  },
};

const colors = {
  ...mode.light,
  ...Brand,
  dim: {
    ...Dim,
  },
};

export { mode, Brand, Light, Dark, Dim };

export default colors;
