import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';
import { SelectKDS } from "@theme/components/multi-parts/SelectKDS";

const helpers = createMultiStyleConfigHelpers(['field', 'icon']);

export const Select = helpers.defineMultiStyleConfig({
  baseStyle: {
    field: {
      backgroundColor: 'white',
      paddingLeft: '10px !important',
    },
    icon: {
      fontWeight: 'medium',
      lineHeight: 'normal',
      color: 'gray.600',
    },
  },
  // sizes: {},
  variants: {
    ...SelectKDS.variants
  },
  // defaultProps: {},
});
