import counselingSlice from '@features/counseling/counselingSlice';
import counterSlice from '@features/counter/counterSlice';
import employeeSlice from '@features/employee/employeeSlice';
import evaluationSlice from '@features/evaluation/evaluationSlice';
import modalSlice from '@features/modal/modalSlice';
import recipientSlice from '@features/recipient/recipientSlice';
import salarySlice from '@features/salary/salarySlice';
import scheduleSlice from '@features/schedule/scheduleSlice';
import userSlice from '@features/user/userSlice';

import { configureStore } from '@reduxjs/toolkit';

export function makeStore() {
  return configureStore({
    reducer: {
      [counterSlice.name]: counterSlice.reducer,
      [userSlice.name]: userSlice.reducer,
      [recipientSlice.name]: recipientSlice.reducer,
      [modalSlice.name]: modalSlice.reducer,
      [evaluationSlice.name]: evaluationSlice.reducer,
      [counselingSlice.name]: counselingSlice.reducer,
      [employeeSlice.name]: employeeSlice.reducer,
      [salarySlice.name]: salarySlice.reducer,
      [scheduleSlice.name]: scheduleSlice.reducer,
    },
  });
}

const store = makeStore();

export default store;
