const COLOR_MAP: Record<string, any> = {
  kakao: {color: '#1A1A1A', borderColor: 'kakao.500'},
  naver: {color: '#FFFFFF', borderColor: 'naver.500'},
  facebook: {color: '#FFFFFF', borderColor: 'facebook.500'},
  apple: {color: '#FFFFFF', borderColor: 'apple.500'},
  google: {color: '#808080', borderColor: '#DDDDDD'},
};

const getTheme = () => {
  const keys = ['kakao', 'naver', 'facebook', 'apple', 'google'];
  const keyValue: Record<string, any> = {};
  keys.forEach((key) => {
    keyValue[key] = {
      bg: `${key}.500`,
      color: COLOR_MAP[key].color,
      borderWidth: 1,
      borderColor: COLOR_MAP[key].borderColor,
      _hover: {
        bg: `${key}.600`,
        borderColor: `${key}.600`,
        _disabled: { bg: `${key}.500` }
      },
      _active: { bg: `${key}.700`, borderColor: `${key}.700` }
    };
  });
  return keyValue;
};

export const BadgeKDS = {
  variants: {
    ...getTheme(),
    yellow: { bg: 'yellow.10', color: 'yellow.600' },
    neutral: { color: 'neutral.500', textColor: 'neutral.200', bg: 'neutral.20' },
    darkGreen: { bg: 'green.500', color: 'white' },
    black: {
      paddingTop: '2px',
      paddingRight: '4px',
      paddingBottom: '2px',
      paddingLeft: '4px',
      color: 'white',
      background: 'var(--N200)'
    },
    red: { background: 'var(--R50)', color: 'var(--R600)' },
    blue: { background: 'var(--B50)', color: 'var(--B600)' },
    green: { background: 'var(--G50)', color: 'var(--G600)' },
    redEmphasis: { background: 'var(--R500)', color: 'white' },
    blueEmphasis: { background: 'var(--B500)', color: 'white' },
    greenEmphasis: { background: 'var(--G500)', color: 'white' },
    gray: { background: 'var(--N20)', color: 'var(--N200)' },
    none: { background: 'white', color: 'var(--N200)' },
  },
  sizes: {
    small: {
      height: '18px',
      padding: '2px 4px',
    },
    medium: {
      height: '24px',
      padding: '4px',
    },
    large: {
      height: '32px',
      padding: '4px 8px',
    }
  }
};
