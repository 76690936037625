export const SelectKDS = {
  variants: {
    defaultKDS: {
      field: {
        backgroundColor: 'white',
        paddingRight: '16px',
        paddingLeft: '16px',
        minHeight: '40px',
        border: '1px solid ',
        borderColor: 'var(--N40)',
        fontSize: '15px',
      }
    }
  },
}
